<template>
  <header>
    <div class="header_wrap">
      <div class="header_inner mcontainer">
        <div class="left_side">

          <span class="slide_menu" uk-toggle="target: #wrapper ; cls: is-collapse is-active">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
              <path
                  d="M3 4h18v2H3V4zm0 7h12v2H3v-2zm0 7h18v2H3v-2z" fill="currentColor"></path>
            </svg>
          </span>

          <div id="logo">
            <router-link :to="{name: 'Home'}">
              <img class="logo-color" src="https://static.bcdcnt.net/assets/images/logo1.svg" alt="Bài ca đi cùng năm tháng">
              <img class="logo-color logo_mobile" src="https://static.bcdcnt.net/assets/images/logo1.svg" alt="Bài ca đi cùng năm tháng">
              <img class="logo-white" src="https://static.bcdcnt.net/assets/images/logo-white.svg" alt="Bài ca đi cùng năm tháng">
            </router-link>
          </div>
        </div>

        <player-fixed v-on:playPlaylist="handlePlayPlaylist" v-if="items && items.length" :items="items"
                      :playMode="playMode" v-model:index="itemIndex" :code="code"/>

        <div class="right_side">
          <div class="header_widgets">
            <div v-if="!user" class="flex font-semibold hidden lg:block my-2 space-x-3 text-center text-sm mr-6">
              <router-link :to="{name: 'Login'}" class="py-3 px-4 mr-1 bg-green-600 px-6 py-3 rounded-md shadow text-white">
                Đăng nhập
              </router-link>
              <router-link :to="{name: 'Register'}"
                           class="bg-green-600 px-6 py-3 rounded-md shadow text-white">
                Đăng ký
              </router-link>
            </div>
            <div class="header_search mr-4"><i class="uil-search-alt"></i>
              <input type="text" class="form-control" id="search-input"
                     v-model="q"
                     v-on:focus="handleFocusSearchInput"
                     v-on:keyup.up="keyUp"
                     v-on:keyup.down="keyDown"
                     v-on:keyup.enter="keyEnter"
                     v-on:input="search"
                     placeholder="Tìm kiếm"
                     autocomplete="off">
              <div class="header_search_dropdown show-recent-search uk-drop uk-open uk-drop-bottom-right" v-if="showSearch">
                <templete v-if="q">
                  <div data-simplebar style="height: 40px; max-width: 100vw" class="whitespace-nowrap align-items-center justify-content-end">
                    <div class="radio">
                      <input v-model="searchType" value="all" id="radio-1" name="radio" type="radio" checked>
                      <label for="radio-1"><span class="radio-label"></span>Tất cả</label>
                    </div>
                    <div class="radio">
                      <input v-model="searchType" value="song" id="radio-2" name="radio" type="radio">
                      <label for="radio-2"><span class="radio-label"></span>Tân nhạc</label>
                    </div>
                    <div class="radio">
                      <input v-model="searchType" value="folk" id="radio-3" name="radio" type="radio">
                      <label for="radio-3"><span class="radio-label"></span>Dân ca</label>
                    </div>
                    <div class="radio">
                      <input v-model="searchType" value="instrumental" id="radio-4" name="radio" type="radio">
                      <label for="radio-4"><span class="radio-label"></span>Nhạc không lời</label>
                    </div>
                    <div class="radio">
                      <input v-model="searchType" value="karaoke" id="radio-5" name="radio" type="radio">
                      <label for="radio-5"><span class="radio-label"></span>Thành viên hát</label>
                    </div>
                    <div class="radio">
                      <input v-model="searchType" value="poem" id="radio-6" name="radio" type="radio">
                      <label for="radio-6"><span class="radio-label"></span>Tiếng thơ</label>
                    </div>
                    <div class="radio">
                      <input v-model="searchType" value="artist" id="radio-7" name="radio" type="radio">
                      <label for="radio-7"><span class="radio-label"></span>Nghệ sĩ</label>
                    </div>
                    <div class="radio">
                      <input v-model="searchType" value="composer" id="radio-8" name="radio" type="radio">
                      <label for="radio-8"><span class="radio-label"></span>Nhạc sĩ</label>
                    </div>
                    <div class="radio">
                      <input v-model="searchType" value="poet" id="radio-9" name="radio" type="radio">
                      <label for="radio-9"><span class="radio-label"></span>Nhà thơ</label>
                    </div>
                    <div class="radio">
                      <input v-model="searchType" value="recomposer" id="radio-10" name="radio" type="radio">
                      <label for="radio-10"><span class="radio-label"></span>Soạn giả</label>
                    </div>
                    <div class="radio">
                      <input v-model="searchType" value="document" id="radio-11" name="radio" type="radio">
                      <label for="radio-11"><span class="radio-label"></span>Tư liệu</label>
                    </div>
                    <div class="radio">
                      <input v-model="searchType" value="user" id="radio-12" name="radio" type="radio">
                      <label for="radio-12"><span class="radio-label"></span>Thành viên</label>
                    </div>
                    <div class="radio">
                      <input v-model="searchType" value="tag" id="radio-13" name="radio" type="radio">
                      <label for="radio-13"><span class="radio-label"></span>Tag</label>
                    </div>
                  </div>
                  <div data-simplebar>
                    <div v-if="searchResults[0] && searchResults[0].hits && searchResults[0].hits.length" class="grid grid-cols-1 gap-4" :class="{'md:grid-cols-3' : searchType === 'all'}">
                      <div v-for="result in searchResults" :key="'search' + result.type" :id="'search' + result.type">
                        <div v-if="['song', 'folk', 'instrumental', 'karaoke', 'poem'].indexOf(result.type) > -1">
                          <h4 class="search_title">
                            <template v-if="result.type === 'song'">Tân nhạc</template>
                            <template v-if="result.type === 'folk'">Dân ca</template>
                            <template v-if="result.type === 'instrumental'">Nhạc không lời</template>
                            <template v-if="result.type === 'karaoke'">Thành viên hát</template>
                            <template v-if="result.type === 'poem'">Tiếng thơ</template>
                          </h4>

                          <search-item-song
                              v-on:click="gotoRoute(song)"
                              v-on:searchByComposer="handleSearchByComposer"
                              :active="getActive(song.index)"
                              v-for="song in result.hits"
                              :song="song" :key="song._search_type + song.id">
                          </search-item-song>
                        </div>
                        <div v-if="['artist', 'composer', 'recomposer', 'poet'].indexOf(result.type) > -1">
                          <h4 class="search_title">
                            <template v-if="result.type === 'artist'">Nghệ sĩ</template>
                            <template v-if="result.type === 'composer'">Nhạc sĩ</template>
                            <template v-if="result.type === 'poet'">Nhà thơ</template>
                            <template v-if="result.type === 'recomposer'">Soạn giả</template>
                          </h4>
                          <search-item-people
                              v-on:click="gotoRoute(people)"
                              :active="getActive(people.index)"
                              v-for="people in result.hits"
                              :people="people" :key="people._search_type + people.id">
                          </search-item-people>
                        </div>
                        <div v-if="result.type === 'document'">
                          <h4 class="search_title">Tư liệu</h4>
                          <search-item-document
                              v-on:click="gotoRoute(document)"
                              :active="getActive(document.index)"
                              v-for="document in result.hits"
                              :document="document" :key="'document' + document.id">
                          </search-item-document>
                        </div>
                        <div v-if="result.type === 'user'">
                          <h4 class="search_title">Thành viên</h4>
                          <search-item-user
                              v-on:click="gotoRoute(user)"
                              :active="getActive(user.index)"
                              v-for="user in result.hits"
                              :user="user" :key="'user' + user.id">
                          </search-item-user>
                        </div>
                        <div v-if="result.type === 'tag'">
                          <h4 class="search_title">Tag</h4>
                          <search-item-tag
                              v-on:click="gotoRoute(tag)"
                              :active="getActive(tag.index)"
                              v-for="tag in result.hits"
                              :tag="tag" :key="'tag' + tag.id">
                          </search-item-tag>
                        </div>
                      </div>
                    </div>
                    <div class="text-center mt-6" v-if="!searchResults[0] || !searchResults[0].hits || !searchResults[0].hits.length">Không tìm thấy kết quả nào</div>
                  </div>
                </templete>

                <templete v-if="!q && recentSearches.items && recentSearches.items.length">
                  <h4 class="search_title">Tìm kiếm gần đây</h4>

                  <template v-for="item in recentSearches.items" :key="'recentSearch' + item._search_type + item.id">
                    <search-item-song
                        v-on:click="gotoRoute(item)"
                        v-if="item._search_category === 'song'"
                        :song="item">
                    </search-item-song>

                    <search-item-people
                        v-on:click="gotoRoute(item)"
                        v-if="item._search_category === 'people'"
                        :people="item">
                    </search-item-people>

                    <search-item-document
                        v-on:click="gotoRoute(item)"
                        v-if="item._search_type === 'document'"
                        :document="item">
                    </search-item-document>
                  </template>
                </templete>
              </div>
            </div>
            <label v-on:click="handleClickSearchIcon" for="search-input"
                   class="header-search-icon cursor-pointer"> </label>
            
            <a v-if="user" href="#" class="is_icon" uk-tooltip="title: Thông báo" v-on:click="resetUnread">
              <svg fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z"></path>
              </svg>
              <span v-if="unread > 0">{{ unread }}</span>
            </a>
            <div v-if="user" uk-drop="mode: click" class="header_dropdown notification_dropdown">
              <div class="dropdown_scrollbar" data-simplebar>
                <div class="drop_headline">
                  <h4>Thông báo</h4>
                  <div class="btn_action">
                    <a href="#" v-on:click="markAsReadAll" uk-tooltip="title: Đánh dấu tất cả đã đọc">
                      <ion-icon name="checkbox-outline"></ion-icon>
                    </a>
                  </div>
                </div>
                <ul>
                  <li :class="!notification.is_read ? 'not-read mb-1' : 'mb-1'"
                      v-for="(notification, index) in notifications.data" :key="'notification' + index">
                    <a href="javascript:void(0)" v-on:click="goToNotification(notification)">
                      <div class="drop_avatar">
                        <img
                            :src="notification.sender && notification.sender.avatar ? notification.sender.avatar.url : notification.sender ? 'https://static.bcdcnt.net/assets/images/avatars/avatar-1.jpg' : 'https://static.bcdcnt.net/assets/images/bcdcnt/logo-core.png'"
                            class="object-cover h-full w-full">
                      </div>
                      <span class="drop_icon bg-gradient-primary">
                        <i class="icon-feather-heart" v-if="notification.action === 'love_comment'"></i>
                        <i class="icon-feather-at-sign" v-if="notification.action === 'mention'"></i>
                        <i class="icon-feather-plus" v-if="notification.action === 'reward_point'"></i>
                        <i class="far fa-hand-paper" v-if="notification.action === 'process_comment_request'"></i>
                        <i class="far fa-comment" v-if="notification.action === 'comment'"></i>
                        <i class="far fa-envelope" v-if="notification.action === 'ticket_created'"></i>
                        <i class="fa fa-gift" v-if="notification.action.indexOf('gift') >= 0"></i>
                        <i class="fa fa-cloud" v-if="notification.action === 'process_upload'"></i>
                      </span>
                      <div class="drop_text">
                        <p>
                          <strong v-if="notification.sender">{{notification.sender.username}}</strong>
                          {{ notification.content }}
                        </p>
                        <time>{{ timeago(notification.created_at) }}</time>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
              <router-link v-if="notifications.paginatorInfo.lastPage > 1" class="uk-drop-close text-center py-2 block"
                           :to="{name: 'NotificationList'}">Xem tất cả
              </router-link>
            </div>

            <a v-if="user" href="javascript:void(0)" class="user-header" id="user-header">
              <img :src="user.avatar ? user.avatar.url : 'https://static.bcdcnt.net/assets/images/avatars/avatar-2.jpg'"
                   class="is_avatar object-cover" alt="">
            </a>

            <div uk-drop="mode: click;offset:5;delay-hide:200" class="header_dropdown profile_dropdown" v-if="user">
              <div class="dropdown_scrollbar" data-simplebar>
                <router-link :to="{name: 'UserDetail', params: {id: user.id}}" class="user">
                  <div class="user_avatar">
                    <img :src="user.avatar ? user.avatar.url : 'https://static.bcdcnt.net/assets/images/avatars/avatar-2.jpg'"
                         class="object-cover"
                         alt="" style="width: 45px;height: 45px;max-width:inherit;">
                  </div>
                  <div class="user_name">
                    <div>{{ user.username }}</div>
                    <span>{{ user.email }}</span>
                  </div>
                </router-link>
                <hr>
                <router-link :to="{name: 'UploadList'}" class="is-link uk-dropdown-close">
                  <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Cloud
                    Upload</title>
                    <path
                        d="M320 367.79h76c55 0 100-29.21 100-83.6s-53-81.47-96-83.6c-8.89-85.06-71-136.8-144-136.8-69 0-113.44 45.79-128 91.2-60 5.7-112 43.88-112 106.4s54 106.4 120 106.4h56"
                        fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                        stroke-width="32"/>
                    <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                          stroke-width="32" d="M320 255.79l-64-64-64 64M256 448.21V207.79"/>
                  </svg>
                  <span>Upload</span>
                </router-link>
                <hr>
                <router-link :to="{name: 'MyGifts'}">
                  <svg fill="currentColor" class="text-yellow-500" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5 5a3 3 0 015-2.236A3 3 0 0114.83 6H16a2 2 0 110 4h-5V9a1 1 0 10-2 0v1H4a2 2 0 110-4h1.17C5.06 5.687 5 5.35 5 5zm4 1V5a1 1 0 10-1 1h1zm3 0a1 1 0 10-1-1v1h1z" clip-rule="evenodd"></path><path d="M9 11H3v5a2 2 0 002 2h4v-7zM11 18h4a2 2 0 002-2v-5h-6v7z"></path></svg>
                  Quà tặng của tôi
                </router-link>
                <router-link :to="{name: 'TicketList'}">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="text-blue-500">
                    <path d="M2 5a2 2 0 012-2h7a2 2 0 012 2v4a2 2 0 01-2 2H9l-3 3v-3H4a2 2 0 01-2-2V5z"></path>
                    <path
                        d="M15 7v2a4 4 0 01-4 4H9.828l-1.766 1.767c.28.149.599.233.938.233h2l3 3v-3h2a2 2 0 002-2V9a2 2 0 00-2-2h-1z"></path>
                  </svg>
                  Hòm thư hỗ trợ
                </router-link>
                <router-link :to="{name: 'MyPlaylist'}">
                  <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 17.98 17.17">
                    <g data-name="Layer 2">
                      <g id="playlist_icon" data-name="playlist icon" class="cls-1">
                        <g id="playlist_icon-2" data-name="playlist icon">
                          <path class="cls-2"
                                d="M.42.93A.44.44,0,0,0,0,1.38a.44.44,0,0,0,.42.45.44.44,0,0,0,.42-.45A.44.44,0,0,0,.42.93Zm0,5.41a.45.45,0,0,0,0,.91.45.45,0,0,0,0-.91Zm1.31.91H9.39a.45.45,0,0,0,.45-.45h0a.45.45,0,0,0-.45-.45H1.73a.45.45,0,0,0-.45.45h0A.45.45,0,0,0,1.73,7.24Zm0-5.41H9.39a.45.45,0,0,0,.45-.45h0A.45.45,0,0,0,9.39.93H1.73a.45.45,0,0,0-.45.45h0A.45.45,0,0,0,1.73,1.84ZM18,8.13h0Zm-.9-3.54,0,0h0A8.74,8.74,0,0,0,12.25.1.42.42,0,0,0,12.09,0L12,0V0h0a.43.43,0,0,0-.43.43v10.7A3.35,3.35,0,0,0,9,9.93a3.53,3.53,0,0,0-3.44,3.61A3.53,3.53,0,0,0,9,17.15a3.48,3.48,0,0,0,3.37-2.93.42.42,0,0,0,.06-.21V1.16A7.89,7.89,0,0,1,16.31,5h0a.44.44,0,0,0,0,.08.42.42,0,0,0,.59.16A.46.46,0,0,0,17.09,4.6ZM9,16.24a2.64,2.64,0,0,1-2.56-2.7A2.64,2.64,0,0,1,9,10.84a2.64,2.64,0,0,1,2.56,2.7A2.64,2.64,0,0,1,9,16.24Zm-4.73-4.5H1.73a.45.45,0,0,0-.45.45h0a.45.45,0,0,0,.45.45H4.27a.45.45,0,0,0,.45-.45h0A.45.45,0,0,0,4.27,11.74ZM12,17.17l.06,0H12ZM.42,11.74a.45.45,0,0,0,0,.91.45.45,0,0,0,0-.91Z"></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                  Playlist của tôi
                </router-link>
                <router-link :to="{name: 'MyDiscussions'}">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="text-blue-500">
                    <path d="M2 5a2 2 0 012-2h7a2 2 0 012 2v4a2 2 0 01-2 2H9l-3 3v-3H4a2 2 0 01-2-2V5z"></path>
                    <path
                        d="M15 7v2a4 4 0 01-4 4H9.828l-1.766 1.767c.28.149.599.233.938.233h2l3 3v-3h2a2 2 0 002-2V9a2 2 0 00-2-2h-1z"></path>
                  </svg>
                  Chủ đề của tôi
                </router-link>
                <router-link :to="{name: 'MyAccount'}">
                  <svg fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                          d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
                          clip-rule="evenodd"></path>
                  </svg>
                  Tài khoản của tôi
                </router-link>
                <router-link :to="{name: 'PageDetail', params: {slug: 'ung-ho'}}">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" fill="currentColor">
                    <g><g><path fill="#000000" d="M150.3,157c1.2,0.8,2.5,1.2,3.8,1.2c1.3,0,2.7-0.4,3.8-1.2c2.3-1.6,57.4-38.6,77.7-75.7c5-7.8,7.7-16.8,7.7-26c0-26.5-21.5-48-48-48c-19.5,0-33.9,16.2-41.1,24.6c-7.3-8.5-21.7-24.6-41.1-24.6c-26.5,0-48,21.5-48,48c0,9.2,2.7,18.2,7.7,26C92.8,118.4,147.9,155.4,150.3,157z M112.9,21c13.7,0,25.2,13.4,31.4,20.5c3.8,4.5,5.9,6.9,9.8,6.9s6-2.5,9.8-6.9c6.2-7.2,17.6-20.5,31.4-20.5c18.9,0,34.3,15.4,34.3,34.3c0,6.7-1.9,13.1-5.6,18.7c-0.1,0.1-0.2,0.3-0.3,0.5c-15.7,29-56.8,59.5-69.6,68.5c-12.8-9-53.9-39.5-69.6-68.5c-0.1-0.2-0.2-0.3-0.3-0.5c-3.6-5.6-5.6-12.1-5.6-18.7C78.6,36.3,94,21,112.9,21L112.9,21z"/><path fill="#000000" d="M236.6,155.3c-12.5-7.3-23.1,3.5-27,7.6c-8.4,8.6-18.4,17.2-23.5,21.4c-2.2-1-4.6-1.5-7.1-1.6c-15.5-0.8-40-3.6-46.1-7.3c-6.6-3.9-11.9-7.7-16.5-11.1c-13.4-9.7-23.9-17.3-50.1-17.3c-7.6,0-14.3,2.1-20.7,4.2c-6.6,2.1-12.9,4.1-20.4,4.1c-8.3,0-15.1,6.8-15.1,15.1V209c0,8.3,6.8,15.1,15.1,15.1c14.3,0,36.8,0.7,41.7,2.4c8.9,3,29.8,15.4,37.1,19.9c0.1,0,0.2,0.1,0.3,0.2c2.6,1.5,5.6,2.2,8.7,2.2h69.8c7.4,0,15.6-6.8,18.4-11.6c9.1-15.2,41.9-56.5,42.3-56.9c0.3-0.4,0.5-0.8,0.8-1.2C248.4,170.6,245.1,160.4,236.6,155.3L236.6,155.3z M182.7,235h-69.8c-0.7,0-1.3-0.1-1.9-0.5c-2.6-1.6-28-17.2-39.8-21.1c-8.8-3-39.9-3.1-46.1-3.1c-0.8,0-1.4-0.6-1.4-1.4v-38.4c0-0.8,0.6-1.4,1.4-1.4c9.7,0,17.6-2.5,24.6-4.7c6-1.9,11.1-3.5,16.6-3.5c21.8,0,29.4,5.5,42.1,14.7c4.9,3.5,10.4,7.5,17.5,11.8c3.6,2.1,9.5,3.8,16.1,5.1c14.2,2.8,31.8,3.9,36.4,4.1c0.1,0,0.2,0,0.4,0c2.3,0,4.1,1.8,4.1,4.1c0,2.3-1.8,4.1-4.1,4.1c-0.1,0-0.2,0-0.4,0l-51.9,2.7c-4.1-0.1-13.9-7-20.4-11.6c-1.7-1.2-3.3-2.3-4.9-3.5c-3.5-2.4-6.8-4.6-9.9-6.1c-3.4-1.7-7.5-0.3-9.2,3.1c-1.7,3.4-0.3,7.5,3.1,9.2c3.5,1.8,8.2,5,13.1,8.5c10.3,7.3,20,14.2,28.4,14.2c0.1,0,0.2,0,0.4,0l52-2.7c9.7-0.1,17.6-8.1,17.6-17.8c0-2.2-0.4-4.3-1.1-6.3c5.5-4.6,15.4-13.1,24-21.9c5.8-6.1,8.2-6.6,10.2-5.3c1.8,1.1,3.2,3.2,2.4,5.2c-4.6,5.8-33.7,42.7-42.7,57.7C188.1,232.1,184.1,234.8,182.7,235L182.7,235z"/></g></g>
                  </svg>
                  Ủng hộ
                </router-link>
                <a href="javascript:void(0)" v-on:click="toogleNightMode" id="night-mode" class="btn-night-mode flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                        <path d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z"></path>
                      </svg>
                      Chế độ tối
                    <span class="btn-night-mode-switch switch flex items-center">
                        <span class="uk-switch-button"></span>
                    </span>
                </a>
                <hr>
                <router-link :to="{name: 'Logout'}">
                  <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Exit</title>
                    <path
                        d="M320 176v-40a40 40 0 00-40-40H88a40 40 0 00-40 40v240a40 40 0 0040 40h192a40 40 0 0040-40v-40M384 176l80 80-80 80M191 256h273"
                        fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                        stroke-width="32"/>
                  </svg>
                  Đăng xuất
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import ApiService from "../core/services/api.service";
import SearchItemSong from "./search/SearchItemSong";
import SearchItemPeople from "./search/SearchItemPeople";
import SearchItemUser from "./search/SearchItemUser";
import SearchItemDocument from "./search/SearchItemDocument";
import SearchItemTag from "./search/SearchItemTag";
import PlayerFixed from "./PlayerFixed";
import axios from "axios";
import {
  canManageSheet,
  canManageComment,
  getNotificationRouteName, getNotificationRouteParams, getNotificationRouteQuery,
  getPrefixFromSongType,
  getRoutePrefixPeople, isAdmin,
  timeago, canManageSong, canManagePeople, canManageTicket, canManageUser, canManageUpload, updateMe, getPeopleType, canManageDocument
} from "../core/services/utils.service";
import {getUser } from "../core/services/jwt.service";

const CancelToken = axios.CancelToken;

export default {
  name: 'Header',
  components: {SearchItemDocument, SearchItemSong, SearchItemPeople, SearchItemUser, SearchItemTag, PlayerFixed},
  props: {
    user: Object,
    items: Array,
    playMode: String,
    playTime: null,
    code: String,
    songIndex: Number
  },
  data() {
    return {
      q: "",
      searchType: "song",
      showSearch: false,
      searchSource: null,
      activeIndex: -1,
      searchResults: {},
      searchItems: [],
      itemIndex: 0,
      unread: 0,
      notifications: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0
        }
      },
      recentSearches: {
        items: []
      }
    }
  },
  methods: {
    search() {
      this.searchItems = [];
      this.cancelSearch();
      this.searchSource = CancelToken.source();
      this.showSearch = true;

      window.$("#wrapper").addClass("show-searchbox");

      let query = `mutation($q: String!, $limit: Int, $type: String!, $from: Int) {
        search(q: $q, limit: $limit, from: $from, type: $type)
      }`;

      let limit = this.searchType === "all" ? 5: 50;

      ApiService.graphql(query, {q: this.q, type: this.searchType, limit: limit, from: 0}, this.searchSource.token)
          .then(({data}) => {
            if (data.data && data.data.search) {
              this.searchResults = data.data.search;
              this.searchSource = null;
              let index = -1;
              for (let i = 0; i < this.searchResults.length; i++) {
                if (this.searchResults[i].hits && this.searchResults[i].hits.length) {
                  for (let j = 0; j < this.searchResults[i].hits.length; j++) {
                    index++;
                    this.searchResults[i].hits[j].index = index;
                    this.searchItems.push(this.searchResults[i].hits[j]);
                  }
                }
              }
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    cancelSearch() {
      if (this.searchSource) {
        this.searchSource.cancel();
      }
    },
    keyUp() {
      this.activeIndex = this.activeIndex - 1;
      if (this.activeIndex < 0) {
        this.activeIndex = 0;
      }
    },
    keyDown() {
      this.activeIndex = this.activeIndex + 1;
      if (this.activeIndex >= this.searchItems.length - 1) {
        this.activeIndex = this.searchItems.length - 1;
      }
    },
    getActive(index) {
      return this.activeIndex === index;
    },
    keyEnter() {
      if (this.searchItems[this.activeIndex]) {
        this.gotoRoute(this.searchItems[this.activeIndex]);
      }
    },
    gotoRoute(item) {
      if (item._search_category === "song") {
        this.$router.push({
          name: 'SongDetail',
          params: {id: item.id, slug: item.slug, prefix: getPrefixFromSongType(item._search_type)}
        });
        this.addRecentSearch(item._search_type, item.id);
      } else if (item._search_category === "people") {
        this.$router.push({name: 'PeopleDetail', params: {prefix: getRoutePrefixPeople(item._search_type), slug: item.slug}});
        this.addRecentSearch(item._search_type, item.id);
      } else if (item._search_type === "user") {
        this.$router.push({name: 'UserDetail', params: {id: item.id}});
      } else if (item._search_type === "document") {
        this.$router.push({name: 'DocumentDetail', params: {id: item.id, slug: item.slug}});
        this.addRecentSearch("document", item.id);
      }

      window.$(".header-search-icon").click();
    },
    loadNotifications() {
      let query = `query {
          me {
            unread
            notifications(first: 10, orderBy: [{column: "created_at", order:DESC}]){
              data {
                code
                content
                action
                is_read
                object_type
                object_id
                created_at
                sender {
                  username
                  id
                  avatar {
                    url
                  }
                }
                extra
              }
              paginatorInfo {
                lastPage
              }
            }
          }
        }`;

      ApiService.graphql(query)
          .then(({data}) => {
            if (data.data && data.data.me) {
              this.unread = data.data.me.unread;
              this.notifications = data.data.me.notifications;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    timeago(x) {
      return timeago(x);
    },
    goToNotification(notification) {
      if (!notification.is_read) {
        notification.is_read = 1;
        this.markAsRead(notification);
      }
      let extra = JSON.parse(notification.extra);
      if (extra.router.exactly) {
        this.$router.push(extra.router.exactly);
      } else if (extra.router.url) {
        window.open(extra.router.url);
      } else if (extra.router.name) {
        this.$router.push({
          name: extra.router.name
        });
        console.log('xxxx');
      } else {
        this.$router.push({
          name: getNotificationRouteName(extra.router.type),
          params: getNotificationRouteParams(extra.router.type, extra.router),
          query: getNotificationRouteQuery(notification, extra)
        });
      }
     
      window.$(".notification_dropdown").removeClass("uk-open");
    },
    handleFocusSearchInput() {
      this.showSearch = true;

      if (!this.q && this.recentSearches.items && this.recentSearches.items.length) {
        window.$("#wrapper").addClass("show-searchbox");
        window.$("#wrapper").removeClass("hide-searchbox");
      }
    },
    addRecentSearch(object_type, object_id) {
      let query = `mutation($object_type: String!, $object_id: ID!) {
        addRecentSearch(object_type: $object_type, object_id: $object_id) {
          id
        }
      }`;

      ApiService.graphql(query, {object_type: object_type, object_id: object_id})
          .then(({data}) => {
            if (data.data && data.data.addRecentSearch) {
              updateMe("search");
            }
          });
    },
    markAsRead(notification) {
      let query = `mutation($code: String!) {
        markAsRead(code: $code)
      }`;

      ApiService.graphql(query, {code: notification.code})
          .then(({data}) => {
            if (data.data && data.data.markAsRead) {
              this.loadNotifications();
            } else {
              notification.is_read = 0;
            }
          });
    },
    markAsReadAll() {
      let query = `mutation {
        markAsReadAll
      }`;

      ApiService.graphql(query)
          .then(({data}) => {
            if (data.data && data.data.markAsReadAll) {
              this.loadNotifications();
            }
          });
    },
    resetUnread() {
      if (this.unread === 0) {
        return;
      }
      this.unread = 0;
      let query = `mutation {
        resetUnread
      }`;

      ApiService.graphql(query)
          .then(({data}) => {
            if (data.data && data.data.resetUnread) {
              console.log("reset ok");
            }
          });
    },
    handlePlayPlaylist(items, mode, isAdded, prefix) {
      this.$emit("playPlaylist", items, mode, isAdded, prefix);
    },
    handleClickSearchIcon() {
      this.q = '';
      if (window.$("#wrapper").hasClass("show-searchbox")) {
        window.$("#wrapper").removeClass("show-searchbox");
        window.$("#wrapper").addClass("hide-searchbox");
      } else {
        window.$("#wrapper").addClass("show-searchbox");
        window.$("#wrapper").removeClass("hide-searchbox");
      }
    },
    handleSearchByComposer(composer) {
      if (this.q) {
        let tmp = this.q.split("+");
        if (tmp[1]) {
          this.q = tmp[0] + "+" + composer;
        } else {
          this.q = this.q + "+" + composer;
        }
      } else {
        this.q = composer;
      }

      this.search();
    },
    transformRecentSearches(recentSearches) {
      this.recentSearches.items = [];

      recentSearches.map((search) => {
        const item = search.object;
        if (!item) {
          return false;
        }
        if (parseInt(search.weight) === 0) {
          let artists = [];
          if (item.artists && item.artists.data) {
            item.artists.data.map((user) => {
              artists.push(user.title);
            });
          }
          let song = {
            id: item.id,
            title: item.title,
            slug: item.slug,
            views: item.views,
            _search_type: item["__typename"].toLowerCase(),
            _search_category: "song",
            artist: artists.join(" - "),
            image: item.thumbnail && item.thumbnail.url ? item.thumbnail.url : null
          };
          this.recentSearches.items.push(song);
        } else if (parseInt(search.weight) === 1) {
          let people = {
            id: item.id,
            title: item.title,
            slug: item.slug,
            _search_type: item["__typename"].toLowerCase(),
            people_type: getPeopleType(item["__typename"]),
            _search_category: "people",
            image: item.avatar && item.avatar.url ? item.avatar.url : null
          };
          this.recentSearches.items.push(people);
        } else if (parseInt(search.weight) === 2) {
          let document = {
            id: item.id,
            title: item.title,
            slug: item.slug,
            _search_type: "document",
            image: item.thumbnail && item.thumbnail.url ? item.thumbnail.url : null
          };
          this.recentSearches.items.push(document);
        }
      });
    },
    reloadMe() {
      let user = getUser();
      if (user) {
        this.loadNotifications();
        if (user.recentSearches && user.recentSearches.data) {
          this.transformRecentSearches(user.recentSearches.data);
        }
      }
    },
    toogleNightMode() {
      window.postMessage({action: "nightMode"}, window.location.origin);
    },
    closeDrop() {
      // window.$(selector).click();
      window.$('body').trigger({
        type: 'keyup',
        which: 27 // Escape key
    });
    }
  },
  watch: {
    songIndex: {
      handler(newVal) {
        this.itemIndex = newVal;
      }
    },
    itemIndex: {
      handler(newVal) {
        this.$emit("update:song-index", newVal);
      }
    },
    searchType: {
      handler() {
        this.search();
      }
    },
    q: {
      handler(newVal) {
        if (newVal) {
          window.$(".header_search_dropdown").removeClass("show-recent-search");
        } else {
          window.$(".header_search_dropdown").addClass("show-recent-search");
        }
      }
    }
  },
  mounted() {
    this.reloadMe();

    window.addEventListener("message", (event) => {
      if (event.data.action === "loadNotifications") {
        this.loadNotifications();
      } else if (event.data.action === "reloadMe") {
        this.reloadMe();
      }
    }, false);
  },
  computed: {
    isAdmin() {
      return isAdmin();
    },
    canManageSheet() {
      return canManageSheet();
    },
    canManageSong() {
      return canManageSong();
    },
    canManagePeople() {
      return canManagePeople();
    },
    canManageComment() {
      return canManageComment();
    },
    canManageTicket() {
      return canManageTicket();
    },
    canManageUser() {
      return canManageUser();
    },
    canManageUpload() {
      return canManageUpload();
    },
    canManageDocument() {
      return canManageDocument();
    }
  }
}
</script>
