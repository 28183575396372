<template>
  <div class="player-fixed flex items-center p-2 relative space-x-4" v-if="song">
    <song-item-player :song="song"/>
    <div class="buttons text-center">
      <div class="flex align-items-center">
        <div v-on:click="playPrev" class="ion mr-2" data-title="Ios Skip Backward Icon" data-name="ios-skip-backward-icon" uk-tooltip="title: Phím tắt: Ctrl ←" title="" aria-expanded="false" tabindex="0"><svg fill="currentColor" width="1em" height="1em" class="ion__svg" viewBox="0 0 512 512"><path d="M175 100v137.8L403.9 98.1c5.3-3.1 12.1.7 12.1 6.9v302c0 6.2-6.7 10-12.1 6.9L175 274.2V412c0 2.2-1.8 4-4 4h-71c-2.2 0-4-1.8-4-4V100c0-2.2 1.8-4 4-4h71c2.2 0 4 1.8 4 4z"></path></svg></div>
        <div v-if="!isPlaying" v-on:click="play" class="ion icon-big" data-title="Ios Play Circle Icon" data-name="ios-play-circle-icon" uk-tooltip="title: Phím tắt: Ctrl+Space" title="" aria-expanded="false" tabindex="0"><svg fill="currentColor" width="1em" height="1em" class="ion__svg" viewBox="0 0 512 512"><path d="M256 48C141.1 48 48 141.1 48 256s93.1 208 208 208 208-93.1 208-208S370.9 48 256 48zm83.8 211.9l-137.2 83c-2.9 1.8-6.7-.4-6.7-3.9V173c0-3.5 3.7-5.7 6.7-3.9l137.2 83c2.9 1.7 2.9 6.1 0 7.8z"></path></svg></div>
        <div v-if="isPlaying" v-on:click="pause" class="ion icon-big" data-title="Ios Pause Icon" data-name="ios-pause-icon" uk-tooltip="title: Phím tắt: Ctrl+Space" title="" aria-expanded="false" tabindex="0"><svg fill="currentColor" width="1em" height="1em" class="ion__svg" viewBox="0 0 512 512"><path d="M199.9 416h-63.8c-4.5 0-8.1-3.6-8.1-8V104c0-4.4 3.6-8 8.1-8h63.8c4.5 0 8.1 3.6 8.1 8v304c0 4.4-3.6 8-8.1 8zM375.9 416h-63.8c-4.5 0-8.1-3.6-8.1-8V104c0-4.4 3.6-8 8.1-8h63.8c4.5 0 8.1 3.6 8.1 8v304c0 4.4-3.6 8-8.1 8z"></path></svg></div>
        <div v-on:click="playNext" class="ion ml-2" data-title="Ios Skip Forward Icon" data-name="ios-skip-forward-icon" uk-tooltip="title: Phím tắt: Ctrl →" title="" aria-expanded="false" tabindex="0"><svg fill="currentColor" width="1em" height="1em" class="ion__svg" viewBox="0 0 512 512"><path d="M337 100v137.8L108.1 98.1C102.7 95 96 98.8 96 105v302c0 6.2 6.7 10 12.1 6.9L337 274.2V412c0 2.2 1.8 4 4 4h71c2.2 0 4-1.8 4-4V100c0-2.2-1.8-4-4-4h-71c-2.2 0-4 1.8-4 4z"></path></svg></div>
      </div>
    </div>
    <div class="repeat-wrap">
      <label class="switch flex items-center" uk-tooltip="title: Tự động next bài">
        <input type="checkbox" v-model="autoPlay">
        <span class="switch-button"></span>
      </label>
    </div>
    <div class="volume-wrap flex align-items-center space-x-2 hidden sm:flex justify-end">
      <div v-if="!audio.muted" v-on:click="mute" class="ion" data-title="Ios Volume High Icon" data-name="ios-volume-high-icon"><svg fill="currentColor" width="1em" height="1em" class="ion__svg" viewBox="0 0 512 512"><path d="M215.4 145.5c-2.2-1.1-4.6-1.6-6.9-1.6-3.6 0-7.1 1.2-10 3.5L133.3 200H80.5c-8.8 0-16 7.2-16 16v80c0 8.8 7.2 16 16 16h52.8l65.2 52.5c2.9 2.3 6.5 3.5 10 3.5 2.3 0 4.7-.5 6.9-1.6 5.6-2.7 9.1-8.3 9.1-14.4V160c0-6.2-3.5-11.8-9.1-14.5zM447.5 256c0-59.3-23.2-114.8-65.4-156.5-4.7-4.7-12.3-4.6-17 .1-4.7 4.7-4.6 12.3.1 17 37.6 37.1 58.2 86.6 58.2 139.4 0 52.8-20.7 102.3-58.2 139.4-4.7 4.7-4.8 12.3-.1 17 2.3 2.4 5.4 3.6 8.5 3.6 3 0 6.1-1.2 8.4-3.5 42.3-41.6 65.5-97.2 65.5-156.5z"></path><path d="M384.9 256c0-43.5-16.6-84.3-46.8-114.9-4.7-4.7-12.3-4.8-17-.1-4.7 4.7-4.8 12.3-.1 17 25.7 26.1 39.9 60.9 39.9 98.1 0 37.2-14.2 72-39.9 98.1-4.7 4.7-4.6 12.3.1 17 2.3 2.3 5.4 3.5 8.4 3.5 3.1 0 6.2-1.2 8.5-3.6 30.2-30.8 46.9-71.6 46.9-115.1z"></path><path d="M287.5 182.5c-4.7-4.7-12.3-4.8-17-.1-4.7 4.7-4.8 12.3-.1 17 14.8 15 23 35.1 23 56.6 0 21.4-8.2 41.5-23 56.6-4.7 4.7-4.6 12.3.1 17 2.3 2.3 5.4 3.5 8.4 3.5 3.1 0 6.2-1.2 8.5-3.6 19.3-19.6 29.9-45.6 29.9-73.4.1-27.9-10.5-54-29.8-73.6z"></path></svg></div>
      <div v-if="audio.muted" v-on:click="unmute" class="ion" data-title="Ios Volume Mute Icon" data-name="ios-volume-mute-icon"><svg fill="currentColor" width="1em" height="1em" class="ion__svg" viewBox="0 0 512 512"><path d="M326.9 145.6c-2.2-1.1-4.6-1.6-6.9-1.6-3.6 0-7.1 1.2-10 3.5L244.8 200H192c-8.8 0-16 7.2-16 16v80c0 8.8 7.2 16 16 16h52.8l65.2 52.5c2.9 2.3 6.5 3.5 10 3.5 2.3 0 4.7-.5 6.9-1.6 5.6-2.7 9.1-8.3 9.1-14.4V160c0-6.2-3.5-11.8-9.1-14.4z"></path></svg></div>
      <vue-slider :dragOnClick="true"
                  v-on:change="handleVolumeChange"
                  v-model="volume" tooltip="none"/>
    </div>
    <div class="flex items-center text-xs">
      <span class="">{{ currentTime }}</span>/
      <span class="">{{ duration }}</span>
    </div>
    <div class="progress-wrap absolute">
      <vue-slider :dragOnClick="true"
                  v-on:dragging="handleDragging"
                  v-on:drag-end="handleDragEnd"
                  v-on:change="handleChange"
                  v-model="percent"
                  tooltip="none"/>
    </div>
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'
import {getAutoplay, saveAutoplay, addPlayedId, destroyPlayedIds, getPlayedIds} from "../core/services/jwt.service";
import {
  getArtistsOfSong,
  getPluralFromSongPrefix,
  getThumbnailOfSong,
  logActivity,
  showNotification
} from "../core/services/utils.service";
import ApiService from "../core/services/api.service";
import SongItemPlayer from "./SongItemPlayer";

export default {
  name: "PlayerFixed",
  components: {
    SongItemPlayer,
    VueSlider
  },
  props: {
    items: {
      type: Array,
      default: function () {
        return [];
      }
    },
    index: {
      type: Number,
      default: 0
    },
    playMode: String,
    playTime: null,
    code: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      song: null,
      audio: new Audio(),
      duration: "00:00",
      currentTime: "00:00",
      percent: 0,
      volume: 100,
      percentDragged: 0,
      autoPlay: false,
      prevSong: null,
      currentIndex: 0,
      isPlaying: false,
      step: 5,
      volumeStep: 0.1,
      editing: false,
      loggedListenActivity: false
    }
  },
  methods: {
    loadSong() {
      if (this.items[this.currentIndex]) {
        this.song = this.items[this.currentIndex];
        this.audio.src = this.song.file.audio_url;
        this.audio.load();

        if (this.currentIndex === this.items.length - 1 && this.playMode === "song") {
          this.loadSuggestSongs();
        }

        window.postMessage({action: "currentSong", value: JSON.parse(JSON.stringify(this.song))}, window.location.origin);
      }
    },
    playSong() {
      this.loadSong();
      this.play();
      this.loggedListenActivity = false;
    },
    play() {
      this.audio.play()
          .then(() => {
            this.isPlaying = true;
          })
          .catch((e) => {
            console.log(e.message);
            this.isPlaying = false;
          });
    },
    pause() {
      this.audio.pause();
      this.isPlaying = false;
    },
    mute() {
      this.audio.muted = true;
    },
    unmute() {
      this.audio.muted = false;
    },
    playNext() {
      if ((this.currentIndex + 1) < this.items.length) {
        if (this.playMode === "playlist") {
          this.currentIndex++;
          this.loadSong();
          this.play();
        } else {
          this.song = this.items[this.currentIndex+1];
          this.$router.push( {name: "SongDetail", params: {id: this.song.id, slug: this.song.slug, prefix: this.song.prefix}});
          addPlayedId(this.song.id, this.song.prefix);
        }
       
        this.displayNotification();
      }
    },
    playPrev() {
      if ((this.currentIndex - 1) >= 0) {
        this.currentIndex--;
        this.loadSong();
        this.play();
        this.displayNotification();
      }
    },
    displayNotification() {
      showNotification("Đang phát: " + this.song.title, {
        body: getArtistsOfSong(this.song),
        icon: getThumbnailOfSong(this.song)
      });
    },
    convertTime(seconds) {
      let minute = parseInt(seconds / 60);
      let second = parseInt(seconds - minute * 60);
      if (minute < 10) {
        minute = "0" + minute;
      }
      if (second < 10) {
        second = "0" + second;
      }

      return `${minute}:${second}`;
    },
    convertTimeToDuration(time) {
      let duration = 0;

      let tmp = time.split(":");
      if (tmp.length === 1) {
        duration = parseInt(time);
      } else if (tmp.length === 2) {
        duration = parseInt(tmp[0]) * 60 + parseInt(tmp[1]);
      } else if (tmp.length === 3) {
        duration = parseInt(tmp[0]) * 3600 + parseInt(tmp[1]) * 60 + parseInt(tmp[2]);
      }

      return duration;
    },
    handleDragEnd() {
      console.log(this.percentDragged, this.percentDragged/100, this.audio.duration);
      this.audio.currentTime = this.percentDragged / 100 * this.audio.duration;
    },
    handleDragging(value) {
      this.percentDragged = value;
    },
    handleChange(value) {
      this.percentDragged = value;
    },
    handleVolumeChange(value) {
      this.audio.volume = value / 100;
    },
    loadSuggestSongs() {
      if (!this.song || !this.song.prefix || this.song.prefix === "vov") {
        return;
      }
      let type = getPluralFromSongPrefix(this.song.prefix);
      let query = `query($id: ID!, $type: String!, $exceptIds: Mixed) {
        suggestSongs(first: 5, id: $id, type: $type, exceptIds: $exceptIds) {
          data {
            id
            title
            slug
            file_type
            views
            thumbnail {
              url
            }
            file {
              audio_url
            }
            ... on Song {
                  artists(first: 100, orderBy: [{column: "song_artist.order", order: ASC}]) {
                    data {
                      title
                      slug
                      avatar {
                        url
                      }
                    }
                  }
                }
            ... on Folk {
                  artists(first: 100, orderBy: [{column: "folk_artist.order", order: ASC}]) {
                    data {
                      title
                      slug
                      avatar {
                        url
                      }
                    }
                  }
                }
            ... on Instrumental {
                  artists(first: 100, orderBy: [{column: "instrumental_artist.order", order: ASC}]) {
                    data {
                      title
                      slug
                      avatar {
                        url
                      }
                    }
                  }
                }
            ... on Poem {
                  artists(first: 100, orderBy: [{column: "poem_artist.order", order: ASC}]) {
                    data {
                      title
                      slug
                      avatar {
                        url
                      }
                    }
                  }
                }
            ... on Karaoke {
                  users(first: 100, orderBy: [{column: "karaoke_user.order", order: ASC}]) {
                    data {
                      username
                      id
                      avatar {
                        url
                      }
                    }
                  }
                }
          }
        }
      }`;

      var exceptIds = [];
      let playedIds = getPlayedIds();
      if (playedIds && playedIds.ids) {
        exceptIds = playedIds.ids;
      }

      ApiService.graphql(query, {id: this.song.id, type: type, exceptIds: exceptIds})
          .then(({data}) => {
            if (data.data && data.data.suggestSongs) {
              this.suggestSongs = data.data.suggestSongs;
              if (this.suggestSongs.data && this.suggestSongs.data.length) {
                this.$emit("playPlaylist", this.suggestSongs.data, "song", true, this.song.prefix);
                window.postMessage({action: "suggestSongs", value: {data: this.suggestSongs.data, id: this.song.id, type: type}}, window.location.origin);
              }
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
  },
  created() {
    this.audio.addEventListener("loadedmetadata", () => {
      this.duration = this.convertTime(this.audio.duration);
    });

    this.audio.addEventListener("ended", () => {
      this.isPlaying = false;
      if (this.autoPlay !== true) {
        this.play();
      } else {
        this.playNext();
      }
      this.loggedListenActivity = false;
    });

    this.audio.addEventListener("pause", () => {
      this.isPlaying = false;
    });

    this.audio.addEventListener("playing", () => {
      this.isPlaying = true;
    });

    this.audio.addEventListener("stalled", () => {
      this.isPlaying = false;
    });

    this.audio.addEventListener("timeupdate", () => {
      this.currentTime = this.convertTime(this.audio.currentTime);
      this.percent = (this.audio.currentTime / this.audio.duration) * 100;
      if (isNaN(this.percent)) {
        this.percent = 0;
      }
      if (this.song && this.audio.currentTime >= 1 && !this.loggedListenActivity) {
        let type = getPluralFromSongPrefix(this.song.prefix);
        logActivity("listen", type, this.song.id);
        this.loggedListenActivity = true;
      }
    });

    this.audio.addEventListener("volumechange", () => {
      this.volume = this.audio.volume * 100;
    });

    window.addEventListener("message", (event) => {
      if (event.data.action === "playTime") {
        this.audio.currentTime = this.convertTimeToDuration(event.data.value);
      }
      if (event.data.action === "isEditing") {
        this.editing = event.data.value;
      }
      if (event.data.action === "stopPlayer") {
        if (this.audio && !this.audio.paused) {
          this.audio.pause();
        }
      }
      if (event.data.action === "clearPlayer") {
        if (this.audio && !this.audio.paused) {
          this.audio.pause();
        }
        this.song = null;
      }
    }, false);

    window.addEventListener("keydown", (event) => {
      if (!this.editing) {
        if (event.ctrlKey || event.shiftKey) {
          switch (event.key) {
            case "ArrowLeft":
              if (event.ctrlKey) {
                this.playPrev();
              } else if (event.shiftKey) {
                this.audio.currentTime = this.audio.currentTime >= this.step ? this.audio.currentTime - this.step : 0;
              }
              break;
            case "ArrowRight":
              if (event.ctrlKey) {
                this.playNext();
              } else if (event.shiftKey) {
                this.audio.currentTime = this.audio.currentTime <= this.audio.duration - this.step ? this.audio.currentTime + this.step : this.audio.duration;
              }
              break;
            case "ArrowUp":
              if (event.ctrlKey) {
                this.audio.volume = this.audio.volume + this.volumeStep <= 1 ? this.audio.volume + this.volumeStep : 1;
              }
              break;
            case "ArrowDown":
              if (event.ctrlKey) {
                this.audio.volume = this.audio.volume - this.volumeStep >= 0 ? this.audio.volume - this.volumeStep : 0;
              }
              break;
            case " ":
              if (this.isPlaying) {
                this.pause();
              } else {
                this.play();
              }
              event.preventDefault();
              break;
          }
        }
      }
    }, false);

    if (this.items.length) {
      this.currentIndex = 0;
    }

    if (this.playTime) {
      this.audio.currentTime = this.convertTimeToDuration(this.playTime);
    }

    this.playSong();
  },
  mounted() {
    this.autoPlay = getAutoplay();
  },
  watch: {
    index: {
      handler(newVal) {
        this.currentIndex = newVal;
      }
    },
    currentIndex: {
      handler(newVal) {
        this.$emit("update:index", newVal);
        window.postMessage({action: "songIndex", value: newVal}, window.location.origin);
      }
    },
    code: {
      handler() {
        this.playSong();
      }
    },
    autoPlay: {
      handler(newVal) {
        saveAutoplay(newVal);
        if (!newVal) {
          destroyPlayedIds();
        }
      }
    },
    playTime: {
      handler(newVal) {
        this.audio.currentTime = this.convertTimeToDuration(newVal);
      }
    }
  }
}

</script>
