<template>
  <router-link :title="document.title" :to="{name: 'DocumentDetail', params: {id: document.id, slug: document.slug}}">
    <div :class="'flex items-center space-x-4 py-1 hover:bg-gray-100 rounded-md -mx-1 px-1' + bgClass">
      <div class="w-14 h-14 flex-shrink-0 rounded-md relative">
        <img :alt="document.title" :src="document.image ? document.image.includes('https') ? document.image : 'https://img.bcdcnt.net/files/' + document.image : 'https://static.bcdcnt.net/assets/images/group/group-3.jpg'" class="h-full rounded-full w-full object-cover">
      </div>
      <div class="flex-1">
        <div class="text-lg font-semibold">
          {{ document.title }}
        </div>
        <div class="text-sm text-gray-500 mt-0.5">
          <span v-if="!document.type">Tư liệu</span>
          <span v-if="document.type === 'news'">Bài viết</span>
          <span v-if="document.type === 'audio' || document.type === 'video'">Âm thanh/Video</span>
          <span v-if="document.type === 'image'">Hình ảnh</span>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>

export default {
  name: "SearchItemDocument",
  props: {
    document: {
      type: Object,
      default() {
        return {
          title: ""
        };
      }
    },
    active: Boolean
  },
  computed: {
    bgClass() {
      return this.active ? ' bg-gray-100' : '';
    }
  }
}
</script>
