<template>
  <div class="sidebar">
    <div class="sidebar_inner" data-simplebar>
      <ul>
        <li v-if="!user" class="block lg:hidden">
          <a v-on:click="goToRoute('Login')">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="text-blue-500">
              <path
                  d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z"></path>
            </svg>
            <span>Đăng nhập</span>
          </a>
        </li>
        <li v-if="!user" class="block lg:hidden">
          <a v-on:click="goToRoute('Register')">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="text-blue-500">
              <path
                  d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z"></path>
            </svg>
            <span>Đăng ký</span>
          </a>
        </li>
        <li>
          <a>
            <img :src="user && user.avatar ? user.avatar.url : 'https://static.bcdcnt.net/assets/images/avatars/avatar-2.jpg'"
                 class="rounded-full mr-4 w-7 h-7 object-cover" alt="">
            <span>{{ user ? user.username : 'Bạn chưa đăng nhập' }}</span>
          </a>
          <ul>
            <li v-if="canManageUpload">
              <a v-on:click="goToRoute('AdminUploadList')">
                <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Cloud
                  Upload</title>
                  <path
                      d="M320 367.79h76c55 0 100-29.21 100-83.6s-53-81.47-96-83.6c-8.89-85.06-71-136.8-144-136.8-69 0-113.44 45.79-128 91.2-60 5.7-112 43.88-112 106.4s54 106.4 120 106.4h56"
                      fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                      stroke-width="32"></path>
                  <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                        stroke-width="32" d="M320 255.79l-64-64-64 64M256 448.21V207.79"></path>
                </svg>
                Quản lý upload
              </a>
            </li>
            <li v-if="canManageComment">
              <a v-on:click="goToRoute('AdminCommentList')">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="text-blue-500">
                  <path d="M2 5a2 2 0 012-2h7a2 2 0 012 2v4a2 2 0 01-2 2H9l-3 3v-3H4a2 2 0 01-2-2V5z"></path>
                  <path
                      d="M15 7v2a4 4 0 01-4 4H9.828l-1.766 1.767c.28.149.599.233.938.233h2l3 3v-3h2a2 2 0 002-2V9a2 2 0 00-2-2h-1z"></path>
                </svg>
                Quản lý bình luận
              </a>
            </li>
            <li v-if="canManageTicket">
              <a v-on:click="goToRoute('AdminTicketList')">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="text-blue-500">
                  <path d="M2 5a2 2 0 012-2h7a2 2 0 012 2v4a2 2 0 01-2 2H9l-3 3v-3H4a2 2 0 01-2-2V5z"></path>
                  <path
                      d="M15 7v2a4 4 0 01-4 4H9.828l-1.766 1.767c.28.149.599.233.938.233h2l3 3v-3h2a2 2 0 002-2V9a2 2 0 00-2-2h-1z"></path>
                </svg>
                Quản lý hỗ trợ
              </a>
            </li>
            <li v-if="canManageUser">
              <a v-on:click="goToRoute('AdminUserList')">
                <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>People</title>
                  <path
                      d="M402 168c-2.93 40.67-33.1 72-66 72s-63.12-31.32-66-72c-3-42.31 26.37-72 66-72s69 30.46 66 72z"
                      fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                      stroke-width="32"/>
                  <path
                      d="M336 304c-65.17 0-127.84 32.37-143.54 95.41-2.08 8.34 3.15 16.59 11.72 16.59h263.65c8.57 0 13.77-8.25 11.72-16.59C463.85 335.36 401.18 304 336 304z"
                      fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="32"/>
                  <path
                      d="M200 185.94c-2.34 32.48-26.72 58.06-53 58.06s-50.7-25.57-53-58.06C91.61 152.15 115.34 128 147 128s55.39 24.77 53 57.94z"
                      fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                      stroke-width="32"/>
                  <path
                      d="M206 306c-18.05-8.27-37.93-11.45-59-11.45-52 0-102.1 25.85-114.65 76.2-1.65 6.66 2.53 13.25 9.37 13.25H154"
                      fill="none" stroke="currentColor" stroke-linecap="round" stroke-miterlimit="10"
                      stroke-width="32"/>
                </svg>
                Quản lý user
              </a>
            </li>
            <li v-if="isAdmin">
              <a v-on:click="goToRoute('AdminRoleList')">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="text-blue-500">
                  <path
                      d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z"></path>
                </svg>
                Quản lý ban, nhóm
              </a>
            </li>
            <li v-if="canManageTag">
              <a v-on:click="goToRoute('AdminTagList')">
                <svg viewBox="0 0 20 20" fill="currentColor" class="text-red-500" xmlns="http://www.w3.org/2000/svg">
                  <path d="M21.07 10.3L15.07 4.29996C14.93 4.15996 14.74 4.07996 14.54 4.07996H3C2.59 4.07996 2.25 4.41996 2.25 4.82996V12.71C2.25 12.91 2.33 13.1 2.47 13.24L8.47 19.23C8.91 19.67 9.49 19.91 10.11 19.91C10.73 19.91 11.32 19.67 11.75 19.23L11.97 19.01C12.01 19.09 12.05 19.17 12.12 19.23C12.57 19.68 13.17 19.91 13.76 19.91C14.35 19.91 14.95 19.68 15.41 19.23L21.06 13.58C21.96 12.68 21.96 11.21 21.06 10.3H21.07ZM10.7 18.17C10.54 18.33 10.34 18.41 10.12 18.41C9.9 18.41 9.69 18.32 9.54 18.17L3.75 12.4V5.57996H10.57L16.35 11.36C16.67 11.68 16.67 12.2 16.35 12.52L10.7 18.17ZM20.01 12.52L14.36 18.17C14.04 18.49 13.51 18.49 13.19 18.17C13.12 18.1 13.05 18.06 12.96 18.02L17.4 13.58C18.3 12.67 18.3 11.2 17.4 10.3L12.68 5.57996H14.22L20 11.36C20.32 11.68 20.32 12.2 20 12.52H20.01ZM8.25 8.49996C8.25 9.18996 7.69 9.74996 7 9.74996C6.31 9.74996 5.75 9.18996 5.75 8.49996C5.75 7.80996 6.31 7.24996 7 7.24996C7.69 7.24996 8.25 7.80996 8.25 8.49996Z" fill="#000000"/>
                </svg>
                Quản lý tag
              </a>
            </li>
            <li v-if="isAdmin">
              <a v-on:click="goToRoute('AdminChangeUsernameRequestList')">
                <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>People</title>
                  <path
                      d="M402 168c-2.93 40.67-33.1 72-66 72s-63.12-31.32-66-72c-3-42.31 26.37-72 66-72s69 30.46 66 72z"
                      fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                      stroke-width="32"/>
                  <path
                      d="M336 304c-65.17 0-127.84 32.37-143.54 95.41-2.08 8.34 3.15 16.59 11.72 16.59h263.65c8.57 0 13.77-8.25 11.72-16.59C463.85 335.36 401.18 304 336 304z"
                      fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="32"/>
                  <path
                      d="M200 185.94c-2.34 32.48-26.72 58.06-53 58.06s-50.7-25.57-53-58.06C91.61 152.15 115.34 128 147 128s55.39 24.77 53 57.94z"
                      fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                      stroke-width="32"/>
                  <path
                      d="M206 306c-18.05-8.27-37.93-11.45-59-11.45-52 0-102.1 25.85-114.65 76.2-1.65 6.66 2.53 13.25 9.37 13.25H154"
                      fill="none" stroke="currentColor" stroke-linecap="round" stroke-miterlimit="10"
                      stroke-width="32"/>
                </svg>
                Quản lý đổi username
              </a>
            </li>
            <li v-if="isAdmin">
              <a v-on:click="goToRoute('AdminToolMergeSong')">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="text-green-500">
                            <path d="M11 17a1 1 0 001.447.894l4-2A1 1 0 0017 15V9.236a1 1 0 00-1.447-.894l-4 2a1 1 0 00-.553.894V17zM15.211 6.276a1 1 0 000-1.788l-4.764-2.382a1 1 0 00-.894 0L4.789 4.488a1 1 0 000 1.788l4.764 2.382a1 1 0 00.894 0l4.764-2.382zM4.447 8.342A1 1 0 003 9.236V15a1 1 0 00.553.894l4 2A1 1 0 009 17v-5.764a1 1 0 00-.553-.894l-4-2z"></path>
                        </svg>
                Gộp bài hát
              </a>
            </li>
            <li v-if="isAdmin">
              <a v-on:click="goToRoute('AdminToolMergePeople')">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="text-green-500">
                            <path d="M11 17a1 1 0 001.447.894l4-2A1 1 0 0017 15V9.236a1 1 0 00-1.447-.894l-4 2a1 1 0 00-.553.894V17zM15.211 6.276a1 1 0 000-1.788l-4.764-2.382a1 1 0 00-.894 0L4.789 4.488a1 1 0 000 1.788l4.764 2.382a1 1 0 00.894 0l4.764-2.382zM4.447 8.342A1 1 0 003 9.236V15a1 1 0 00.553.894l4 2A1 1 0 009 17v-5.764a1 1 0 00-.553-.894l-4-2z"></path>
                        </svg>
                Gộp nghệ sĩ
              </a>
            </li>
            <li v-if="isAdmin">
              <a v-on:click="goToRoute('AdminToolSwapSong')">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="text-green-500">
                            <path d="M11 17a1 1 0 001.447.894l4-2A1 1 0 0017 15V9.236a1 1 0 00-1.447-.894l-4 2a1 1 0 00-.553.894V17zM15.211 6.276a1 1 0 000-1.788l-4.764-2.382a1 1 0 00-.894 0L4.789 4.488a1 1 0 000 1.788l4.764 2.382a1 1 0 00.894 0l4.764-2.382zM4.447 8.342A1 1 0 003 9.236V15a1 1 0 00.553.894l4 2A1 1 0 009 17v-5.764a1 1 0 00-.553-.894l-4-2z"></path>
                        </svg>
                Hoán đổi file nhạc
              </a>
            </li>
            <li v-if="isAdmin">
              <a v-on:click="goToRoute('AdminChangeSongType')">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="text-green-500">
                            <path d="M11 17a1 1 0 001.447.894l4-2A1 1 0 0017 15V9.236a1 1 0 00-1.447-.894l-4 2a1 1 0 00-.553.894V17zM15.211 6.276a1 1 0 000-1.788l-4.764-2.382a1 1 0 00-.894 0L4.789 4.488a1 1 0 000 1.788l4.764 2.382a1 1 0 00.894 0l4.764-2.382zM4.447 8.342A1 1 0 003 9.236V15a1 1 0 00.553.894l4 2A1 1 0 009 17v-5.764a1 1 0 00-.553-.894l-4-2z"></path>
                        </svg>
                Chuyển đổi thể loại
              </a>
            </li>
            <li v-if="isAdmin">
              <a v-on:click="goToRoute('AdminToolImportSheetFromPdf')">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="text-green-500">
                            <path d="M11 17a1 1 0 001.447.894l4-2A1 1 0 0017 15V9.236a1 1 0 00-1.447-.894l-4 2a1 1 0 00-.553.894V17zM15.211 6.276a1 1 0 000-1.788l-4.764-2.382a1 1 0 00-.894 0L4.789 4.488a1 1 0 000 1.788l4.764 2.382a1 1 0 00.894 0l4.764-2.382zM4.447 8.342A1 1 0 003 9.236V15a1 1 0 00.553.894l4 2A1 1 0 009 17v-5.764a1 1 0 00-.553-.894l-4-2z"></path>
                        </svg>
                Import sheet nhạc từ PDF
              </a>
            </li>
            <li v-if="isAdmin">
              <a v-on:click="goToRoute('AdminStatistic')">
                <i class="fas fa-chart-line"></i> Thống kê
              </a>
            </li>
            <li v-if="canManageSong">
              <a v-on:click="goToRoute('AdminSongList')">
                <svg class="text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                     xmlns="http://www.w3.org/2000/svg" aria-hidden="false" style="">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M9 19V6l12-3v13M9 19c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zm12-3c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zM9 10l12-3"></path>
                </svg>
                Quản lý tân nhạc
              </a>
            </li>
            <li v-if="canManageSong">
              <a v-on:click="goToRoute('AdminFolkList')">
                <svg class="text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                     xmlns="http://www.w3.org/2000/svg" aria-hidden="false" style="">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M9 19V6l12-3v13M9 19c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zm12-3c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zM9 10l12-3"></path>
                </svg>
                Quản lý dân ca
              </a>
            </li>
            <li v-if="canManageSong">
              <a v-on:click="goToRoute('AdminInstrumentalList')">
                <svg class="text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                     xmlns="http://www.w3.org/2000/svg" aria-hidden="false" style="">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M9 19V6l12-3v13M9 19c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zm12-3c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zM9 10l12-3"></path>
                </svg>
                Quản lý khí nhạc
              </a>
            </li>
            <li v-if="canManageSong">
              <a v-on:click="goToRoute('AdminKaraokeList')">
                <svg class="text-indigo-900" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                     xmlns="http://www.w3.org/2000/svg" aria-hidden="false" style="">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M9 19V6l12-3v13M9 19c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zm12-3c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zM9 10l12-3"></path>
                </svg>
                Quản lý thành viên hát
              </a>
            </li>
            <li v-if="canManageSong">
              <a v-on:click="goToRoute('AdminPoemList')">
                <svg class="text-yellow-400" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                     xmlns="http://www.w3.org/2000/svg" aria-hidden="false" style="">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M9 19V6l12-3v13M9 19c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zm12-3c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zM9 10l12-3"></path>
                </svg>
                Quản lý tiếng thơ
              </a>
            </li>
            <li v-if="canManagePeople">
              <a v-on:click="goToRoute('AdminComposerList')">
                <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Person
                  Circle</title>
                  <path
                      d="M258.9 48C141.92 46.42 46.42 141.92 48 258.9c1.56 112.19 92.91 203.54 205.1 205.1 117 1.6 212.48-93.9 210.88-210.88C462.44 140.91 371.09 49.56 258.9 48zm126.42 327.25a4 4 0 01-6.14-.32 124.27 124.27 0 00-32.35-29.59C321.37 329 289.11 320 256 320s-65.37 9-90.83 25.34a124.24 124.24 0 00-32.35 29.58 4 4 0 01-6.14.32A175.32 175.32 0 0180 259c-1.63-97.31 78.22-178.76 175.57-179S432 158.81 432 256a175.32 175.32 0 01-46.68 119.25z"/>
                  <path
                      d="M256 144c-19.72 0-37.55 7.39-50.22 20.82s-19 32-17.57 51.93C191.11 256 221.52 288 256 288s64.83-32 67.79-71.24c1.48-19.74-4.8-38.14-17.68-51.82C293.39 151.44 275.59 144 256 144z"/>
                </svg>
                Quản lý nhạc sĩ
              </a>
            </li>
            <li v-if="canManagePeople">
              <a v-on:click="goToRoute('AdminArtistList')">
                <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Person
                  Circle</title>
                  <path
                      d="M258.9 48C141.92 46.42 46.42 141.92 48 258.9c1.56 112.19 92.91 203.54 205.1 205.1 117 1.6 212.48-93.9 210.88-210.88C462.44 140.91 371.09 49.56 258.9 48zm126.42 327.25a4 4 0 01-6.14-.32 124.27 124.27 0 00-32.35-29.59C321.37 329 289.11 320 256 320s-65.37 9-90.83 25.34a124.24 124.24 0 00-32.35 29.58 4 4 0 01-6.14.32A175.32 175.32 0 0180 259c-1.63-97.31 78.22-178.76 175.57-179S432 158.81 432 256a175.32 175.32 0 01-46.68 119.25z"/>
                  <path
                      d="M256 144c-19.72 0-37.55 7.39-50.22 20.82s-19 32-17.57 51.93C191.11 256 221.52 288 256 288s64.83-32 67.79-71.24c1.48-19.74-4.8-38.14-17.68-51.82C293.39 151.44 275.59 144 256 144z"/>
                </svg>
                Quản lý nghệ sĩ
              </a>
            </li>
            <li v-if="canManagePeople">
              <a v-on:click="goToRoute('AdminRecomposerList')">
                <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Person
                  Circle</title>
                  <path
                      d="M258.9 48C141.92 46.42 46.42 141.92 48 258.9c1.56 112.19 92.91 203.54 205.1 205.1 117 1.6 212.48-93.9 210.88-210.88C462.44 140.91 371.09 49.56 258.9 48zm126.42 327.25a4 4 0 01-6.14-.32 124.27 124.27 0 00-32.35-29.59C321.37 329 289.11 320 256 320s-65.37 9-90.83 25.34a124.24 124.24 0 00-32.35 29.58 4 4 0 01-6.14.32A175.32 175.32 0 0180 259c-1.63-97.31 78.22-178.76 175.57-179S432 158.81 432 256a175.32 175.32 0 01-46.68 119.25z"/>
                  <path
                      d="M256 144c-19.72 0-37.55 7.39-50.22 20.82s-19 32-17.57 51.93C191.11 256 221.52 288 256 288s64.83-32 67.79-71.24c1.48-19.74-4.8-38.14-17.68-51.82C293.39 151.44 275.59 144 256 144z"/>
                </svg>
                Quản lý soạn giả
              </a>
            </li>
            <li v-if="canManagePeople">
              <a v-on:click="goToRoute('AdminPoetList')">
                <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Person
                  Circle</title>
                  <path
                      d="M258.9 48C141.92 46.42 46.42 141.92 48 258.9c1.56 112.19 92.91 203.54 205.1 205.1 117 1.6 212.48-93.9 210.88-210.88C462.44 140.91 371.09 49.56 258.9 48zm126.42 327.25a4 4 0 01-6.14-.32 124.27 124.27 0 00-32.35-29.59C321.37 329 289.11 320 256 320s-65.37 9-90.83 25.34a124.24 124.24 0 00-32.35 29.58 4 4 0 01-6.14.32A175.32 175.32 0 0180 259c-1.63-97.31 78.22-178.76 175.57-179S432 158.81 432 256a175.32 175.32 0 01-46.68 119.25z"/>
                  <path
                      d="M256 144c-19.72 0-37.55 7.39-50.22 20.82s-19 32-17.57 51.93C191.11 256 221.52 288 256 288s64.83-32 67.79-71.24c1.48-19.74-4.8-38.14-17.68-51.82C293.39 151.44 275.59 144 256 144z"/>
                </svg>
                Quản lý nhà thơ
              </a>
            </li>
            <li v-if="canManageDocument">
              <a v-on:click="goToRoute('AdminDocumentList')">
                <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Document
                  Text</title>
                  <path
                      d="M416 221.25V416a48 48 0 01-48 48H144a48 48 0 01-48-48V96a48 48 0 0148-48h98.75a32 32 0 0122.62 9.37l141.26 141.26a32 32 0 019.37 22.62z"
                      fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="32"/>
                  <path d="M256 56v120a32 32 0 0032 32h120M176 288h160M176 368h160" fill="none" stroke="currentColor"
                        stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/>
                </svg>
                Quản lý tư liệu
              </a>
            </li>
            <li v-if="canManageSheet">
              <a v-on:click="goToRoute('AdminSheetList')">
                <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Documents</title>
                  <path
                      d="M336 264.13V436c0 24.3-19.05 44-42.95 44H107c-23.95 0-43-19.7-43-44V172a44.26 44.26 0 0144-44h94.12a24.55 24.55 0 0117.49 7.36l109.15 111a25.4 25.4 0 017.24 17.77z"
                      fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="32"></path>
                  <path d="M200 128v108a28.34 28.34 0 0028 28h108" fill="none" stroke="currentColor"
                        stroke-linecap="round" stroke-linejoin="round" stroke-width="32"></path>
                  <path
                      d="M176 128V76a44.26 44.26 0 0144-44h94a24.83 24.83 0 0117.61 7.36l109.15 111A25.09 25.09 0 01448 168v172c0 24.3-19.05 44-42.95 44H344"
                      fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="32"></path>
                  <path d="M312 32v108a28.34 28.34 0 0028 28h108" fill="none" stroke="currentColor"
                        stroke-linecap="round" stroke-linejoin="round" stroke-width="32"></path>
                </svg>
                Quản lý bản nhạc
              </a>
            </li>
            <li v-if="user">
              <a v-on:click="goToRoute('UploadList')">
                <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Cloud
                  Upload</title>
                  <path
                      d="M320 367.79h76c55 0 100-29.21 100-83.6s-53-81.47-96-83.6c-8.89-85.06-71-136.8-144-136.8-69 0-113.44 45.79-128 91.2-60 5.7-112 43.88-112 106.4s54 106.4 120 106.4h56"
                      fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                      stroke-width="32"/>
                  <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                        stroke-width="32" d="M320 255.79l-64-64-64 64M256 448.21V207.79"/>
                </svg>
                <span>Upload</span>
              </a>
            </li>
            <li v-if="user">
              <a v-on:click="goToRoute('MyGifts')">
                <svg fill="currentColor" class="text-yellow-500" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5 5a3 3 0 015-2.236A3 3 0 0114.83 6H16a2 2 0 110 4h-5V9a1 1 0 10-2 0v1H4a2 2 0 110-4h1.17C5.06 5.687 5 5.35 5 5zm4 1V5a1 1 0 10-1 1h1zm3 0a1 1 0 10-1-1v1h1z" clip-rule="evenodd"></path><path d="M9 11H3v5a2 2 0 002 2h4v-7zM11 18h4a2 2 0 002-2v-5h-6v7z"></path></svg>
                Quà tặng của tôi
              </a>
            </li>
            <li v-if="user">
              <a v-on:click="goToRoute('TicketList')">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="text-blue-500">
                  <path d="M2 5a2 2 0 012-2h7a2 2 0 012 2v4a2 2 0 01-2 2H9l-3 3v-3H4a2 2 0 01-2-2V5z"></path>
                  <path
                      d="M15 7v2a4 4 0 01-4 4H9.828l-1.766 1.767c.28.149.599.233.938.233h2l3 3v-3h2a2 2 0 002-2V9a2 2 0 00-2-2h-1z"></path>
                </svg>
                Hòm thư hỗ trợ
              </a>
            </li>
            <li v-if="user">
              <a v-on:click="goToRoute('MyPlaylist')">
                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 17.98 17.17">
                  <g data-name="Layer 2">
                    <g id="playlist_icon" data-name="playlist icon" class="cls-1">
                      <g id="playlist_icon-2" data-name="playlist icon">
                        <path class="cls-2"
                              d="M.42.93A.44.44,0,0,0,0,1.38a.44.44,0,0,0,.42.45.44.44,0,0,0,.42-.45A.44.44,0,0,0,.42.93Zm0,5.41a.45.45,0,0,0,0,.91.45.45,0,0,0,0-.91Zm1.31.91H9.39a.45.45,0,0,0,.45-.45h0a.45.45,0,0,0-.45-.45H1.73a.45.45,0,0,0-.45.45h0A.45.45,0,0,0,1.73,7.24Zm0-5.41H9.39a.45.45,0,0,0,.45-.45h0A.45.45,0,0,0,9.39.93H1.73a.45.45,0,0,0-.45.45h0A.45.45,0,0,0,1.73,1.84ZM18,8.13h0Zm-.9-3.54,0,0h0A8.74,8.74,0,0,0,12.25.1.42.42,0,0,0,12.09,0L12,0V0h0a.43.43,0,0,0-.43.43v10.7A3.35,3.35,0,0,0,9,9.93a3.53,3.53,0,0,0-3.44,3.61A3.53,3.53,0,0,0,9,17.15a3.48,3.48,0,0,0,3.37-2.93.42.42,0,0,0,.06-.21V1.16A7.89,7.89,0,0,1,16.31,5h0a.44.44,0,0,0,0,.08.42.42,0,0,0,.59.16A.46.46,0,0,0,17.09,4.6ZM9,16.24a2.64,2.64,0,0,1-2.56-2.7A2.64,2.64,0,0,1,9,10.84a2.64,2.64,0,0,1,2.56,2.7A2.64,2.64,0,0,1,9,16.24Zm-4.73-4.5H1.73a.45.45,0,0,0-.45.45h0a.45.45,0,0,0,.45.45H4.27a.45.45,0,0,0,.45-.45h0A.45.45,0,0,0,4.27,11.74ZM12,17.17l.06,0H12ZM.42,11.74a.45.45,0,0,0,0,.91.45.45,0,0,0,0-.91Z"></path>
                      </g>
                    </g>
                  </g>
                </svg>
                Playlist của tôi
              </a>
            </li>
            <li v-if="user">
              <a v-on:click="goToRoute('MyDiscussions')">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="text-blue-500">
                  <path d="M2 5a2 2 0 012-2h7a2 2 0 012 2v4a2 2 0 01-2 2H9l-3 3v-3H4a2 2 0 01-2-2V5z"></path>
                  <path
                      d="M15 7v2a4 4 0 01-4 4H9.828l-1.766 1.767c.28.149.599.233.938.233h2l3 3v-3h2a2 2 0 002-2V9a2 2 0 00-2-2h-1z"></path>
                </svg>
                <span>Chủ đề của tôi</span>
              </a>
            </li>
            <li v-if="user">
              <a v-on:click="goToRoute('MyAccount')">
                <svg fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                        d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
                        clip-rule="evenodd"></path>
                </svg>
                Tài khoản của tôi
              </a>
            </li>
            <li v-if="user">
              <a v-on:click="goToRoute('Logout')">
                <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Exit</title>
                  <path
                      d="M320 176v-40a40 40 0 00-40-40H88a40 40 0 00-40 40v240a40 40 0 0040 40h192a40 40 0 0040-40v-40M384 176l80 80-80 80M191 256h273"
                      fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                      stroke-width="32"/>
                </svg>
                Đăng xuất
              </a>
            </li>
          </ul>
        </li>
        <li :class="{'active': isActive('BcdcntList')}">
          <a v-on:click="goToRoute('BcdcntList')">
            <svg class="text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                 xmlns="http://www.w3.org/2000/svg" aria-hidden="false" style="">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M9 19V6l12-3v13M9 19c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zm12-3c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zM9 10l12-3"></path>
            </svg>
            <span>Tân nhạc</span>
          </a>
        </li>
        <li>
          <a>
            <svg class="text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                 xmlns="http://www.w3.org/2000/svg" aria-hidden="false" style="">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M9 19V6l12-3v13M9 19c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zm12-3c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zM9 10l12-3"></path>
            </svg>
            <span>Dân ca</span>
          </a>
          <ul>
            <li>
              <a v-on:click="goToRoute('FolkList')">
                <svg class="text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                     xmlns="http://www.w3.org/2000/svg" aria-hidden="false" style="">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M9 19V6l12-3v13M9 19c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zm12-3c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zM9 10l12-3"></path>
                </svg>
                <span>Tất cả dân ca</span>
              </a>
            </li>
            <li>
              <a v-on:click="goToRoute('FcatList')">
                <svg class="text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                     xmlns="http://www.w3.org/2000/svg" aria-hidden="false" style="">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M9 19V6l12-3v13M9 19c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zm12-3c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zM9 10l12-3"></path>
                </svg>
                <span>Thể loại dân ca</span>
              </a>
            </li>
            <li>
              <a v-on:click="goToRoute('MelodyList')">
                <svg class="text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                     xmlns="http://www.w3.org/2000/svg" aria-hidden="false" style="">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M9 19V6l12-3v13M9 19c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zm12-3c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zM9 10l12-3"></path>
                </svg>
                <span>Làn điệu dân ca</span>
              </a>
            </li>
          </ul>
        </li>
        <li :class="{'active': isActive('InstrumentalList')}">
          <a v-on:click="goToRoute('InstrumentalList')">
            <svg class="text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                 xmlns="http://www.w3.org/2000/svg" aria-hidden="false" style="">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M9 19V6l12-3v13M9 19c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zm12-3c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zM9 10l12-3"></path>
            </svg>
            <span>Khí nhạc</span>
          </a>
        </li>
        <li :class="{'active': isActive('KaraokeList')}">
          <a v-on:click="goToRoute('KaraokeList')">
            <svg class="text-indigo-900" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                 xmlns="http://www.w3.org/2000/svg" aria-hidden="false" style="">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M9 19V6l12-3v13M9 19c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zm12-3c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zM9 10l12-3"></path>
            </svg>
            <span>Thành viên hát</span>
          </a>
        </li>
        <li :class="{'active': isActive('PoemList')}">
          <a v-on:click="goToRoute('PoemList')">
            <svg class="text-yellow-400" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                 xmlns="http://www.w3.org/2000/svg" aria-hidden="false" style="">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M9 19V6l12-3v13M9 19c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zm12-3c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zM9 10l12-3"></path>
            </svg>
            <span>Tiếng thơ</span>
          </a>
        </li>
        <li :class="{'active': isActive('ForumIndex')}">
          <a v-on:click="goToRoute('ForumIndex')">
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" stroke="currentColor"
                 class="text-yellow-500 ionicon s-ion-icon" viewBox="0 0 512 512">
              <path
                  d="M87.48 380c1.2-4.38-1.43-10.47-3.94-14.86a42.63 42.63 0 00-2.54-3.8 199.81 199.81 0 01-33-110C47.64 139.09 140.72 48 255.82 48 356.2 48 440 117.54 459.57 209.85a199 199 0 014.43 41.64c0 112.41-89.49 204.93-204.59 204.93-18.31 0-43-4.6-56.47-8.37s-26.92-8.77-30.39-10.11a31.14 31.14 0 00-11.13-2.07 30.7 30.7 0 00-12.08 2.43L81.5 462.78a15.92 15.92 0 01-4.66 1.22 9.61 9.61 0 01-9.58-9.74 15.85 15.85 0 01.6-3.29z"
                  stroke-linecap="round" stroke-miterlimit="10" class="ionicon-fill-none ionicon-stroke-width"></path>
              <circle cx="160" cy="256" r="32" fill="white"></circle>
              <circle cx="256" cy="256" r="32" fill="white"></circle>
              <circle cx="352" cy="256" r="32" fill="white"></circle>
            </svg>
            <span>Diễn đàn</span>
          </a>
        </li>
        <li :class="{'active': isActive('CommentList')}">
          <a v-on:click="goToRoute('CommentList')">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="text-blue-500">
              <path d="M2 5a2 2 0 012-2h7a2 2 0 012 2v4a2 2 0 01-2 2H9l-3 3v-3H4a2 2 0 01-2-2V5z"></path>
              <path
                  d="M15 7v2a4 4 0 01-4 4H9.828l-1.766 1.767c.28.149.599.233.938.233h2l3 3v-3h2a2 2 0 002-2V9a2 2 0 00-2-2h-1z"></path>
            </svg>
            <span>Bình luận</span>
          </a>
        </li>
        <li>
          <a>
            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Person Circle</title>
              <path
                  d="M258.9 48C141.92 46.42 46.42 141.92 48 258.9c1.56 112.19 92.91 203.54 205.1 205.1 117 1.6 212.48-93.9 210.88-210.88C462.44 140.91 371.09 49.56 258.9 48zm126.42 327.25a4 4 0 01-6.14-.32 124.27 124.27 0 00-32.35-29.59C321.37 329 289.11 320 256 320s-65.37 9-90.83 25.34a124.24 124.24 0 00-32.35 29.58 4 4 0 01-6.14.32A175.32 175.32 0 0180 259c-1.63-97.31 78.22-178.76 175.57-179S432 158.81 432 256a175.32 175.32 0 01-46.68 119.25z"/>
              <path
                  d="M256 144c-19.72 0-37.55 7.39-50.22 20.82s-19 32-17.57 51.93C191.11 256 221.52 288 256 288s64.83-32 67.79-71.24c1.48-19.74-4.8-38.14-17.68-51.82C293.39 151.44 275.59 144 256 144z"/>
            </svg>
            <span>Tác giả nhạc</span>
          </a>
          <ul>
            <li :class="{'active': isActive('ComposerList', {prefix: 'nhac-si'})}">
              <a v-on:click="goToRoute('ComposerList', {prefix: 'nhac-si', type: 'viet-nam'})">
                <span>Nhạc sĩ Việt Nam</span>
              </a>
            </li>
            <li :class="{'active': isActive('ComposerList', {prefix: 'nhac-si'})}">
              <a v-on:click="goToRoute('ComposerList', {prefix: 'nhac-si', type: 'nuoc-ngoai'})">
                <span>Nhạc sĩ nước ngoài</span>
              </a>
            </li>
          </ul>
        </li>
        <li>
          <a>
            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Person Circle</title>
              <path
                  d="M258.9 48C141.92 46.42 46.42 141.92 48 258.9c1.56 112.19 92.91 203.54 205.1 205.1 117 1.6 212.48-93.9 210.88-210.88C462.44 140.91 371.09 49.56 258.9 48zm126.42 327.25a4 4 0 01-6.14-.32 124.27 124.27 0 00-32.35-29.59C321.37 329 289.11 320 256 320s-65.37 9-90.83 25.34a124.24 124.24 0 00-32.35 29.58 4 4 0 01-6.14.32A175.32 175.32 0 0180 259c-1.63-97.31 78.22-178.76 175.57-179S432 158.81 432 256a175.32 175.32 0 01-46.68 119.25z"/>
              <path
                  d="M256 144c-19.72 0-37.55 7.39-50.22 20.82s-19 32-17.57 51.93C191.11 256 221.52 288 256 288s64.83-32 67.79-71.24c1.48-19.74-4.8-38.14-17.68-51.82C293.39 151.44 275.59 144 256 144z"/>
            </svg>
            <span>Tác giả lời</span>
          </a>
          <ul>
            <li :class="{'active': isActive('PeopleList', {prefix: 'nha-tho'})}">
              <a v-on:click="goToRoute('PeopleList', {prefix: 'nha-tho'})">
                <span>Tân nhạc</span>
              </a>
            </li>
            <li :class="{'active': isActive('PeopleList', {prefix: 'soan-gia'})}">
              <a v-on:click="goToRoute('PeopleList', {prefix: 'soan-gia'})">
                <span>Dân ca</span>
              </a>
            </li>
          </ul>
        </li>
        <li>
          <a>
            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Person Circle</title>
              <path
                  d="M258.9 48C141.92 46.42 46.42 141.92 48 258.9c1.56 112.19 92.91 203.54 205.1 205.1 117 1.6 212.48-93.9 210.88-210.88C462.44 140.91 371.09 49.56 258.9 48zm126.42 327.25a4 4 0 01-6.14-.32 124.27 124.27 0 00-32.35-29.59C321.37 329 289.11 320 256 320s-65.37 9-90.83 25.34a124.24 124.24 0 00-32.35 29.58 4 4 0 01-6.14.32A175.32 175.32 0 0180 259c-1.63-97.31 78.22-178.76 175.57-179S432 158.81 432 256a175.32 175.32 0 01-46.68 119.25z"/>
              <path
                  d="M256 144c-19.72 0-37.55 7.39-50.22 20.82s-19 32-17.57 51.93C191.11 256 221.52 288 256 288s64.83-32 67.79-71.24c1.48-19.74-4.8-38.14-17.68-51.82C293.39 151.44 275.59 144 256 144z"/>
            </svg>
            <span>Nghệ sĩ</span>
          </a>
          <ul>
            <li :class="{'active': isActive('ArtistList')}">
              <a v-on:click="goToRoute('ArtistList', {prefix: 'nghe-si', type: 'viet-nam'})">
                <span>Nghệ sĩ Việt Nam</span>
              </a>
            </li>
            <li :class="{'active': isActive('ArtistList')}">
              <a v-on:click="goToRoute('ArtistList', {prefix: 'nghe-si', type: 'nuoc-ngoai'})">
                <span>Nghệ sĩ nước ngoài</span>
              </a>
            </li>
          </ul>
        </li>
        <li>
          <a>
            <i class="icon-feather-users mr-2"></i>
            <span>Đội ngũ</span>
          </a>
          <ul>
            <li :class="{'active': isActive('RoleDetail')}">
              <a v-on:click="goToRoute('RoleDetail', {slug: 'ban-quan-tri'})">
                <span>Ban quản trị</span>
              </a>
            </li>
            <li :class="{'active': isActive('RoleDetail')}">
              <a v-on:click="goToRoute('RoleDetail', {slug: 'ban-bien-tap'})">
                <span>Ban biên tập</span>
              </a>
            </li>
            <li :class="{'active': isActive('RoleDetail')}">
              <a v-on:click="goToRoute('RoleDetail', {slug: 'ban-truyen-thong'})">
                <span>Ban truyền thông</span>
              </a>
            </li>
            <li :class="{'active': isActive('RoleDetail')}">
              <a v-on:click="goToRoute('RoleDetail', {slug: 'cong-tac-vien'})">
                <span>Cộng tác viên</span>
              </a>
            </li>
          </ul>
        </li>
        <li>
          <a>
            <i class="uil uil-info-circle mr-2"></i>
            <span>Cần bạn trợ giúp</span>
          </a>
          <ul>
            <li :class="{'active': isActive('EmptyLyric')}">
              <a v-on:click="goToRoute('EmptyLyric')">
                <span>Lời bài hát</span>
              </a>
            </li>
            <li :class="{'active': isActive('EmptyThumbnail')}">
              <a v-on:click="goToRoute('EmptyThumbnail')">
                <span>Ảnh minh họa</span>
              </a>
            </li>
            <li :class="{'active': isActive('EmptyTagSong')}">
              <a v-on:click="goToRoute('EmptyTagSong')">
                <span>Tag bài hát</span>
              </a>
            </li>
            <li :class="{'active': isActive('EmptyFile')}">
              <a v-on:click="goToRoute('EmptyFile')">
                <span>Khôi phục dữ liệu</span>
              </a>
            </li>
          </ul>
        </li>
        <li :class="{'active': isActive('SheetList')}">
          <a v-on:click="goToRoute('SheetList')">
            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Documents</title>
              <path
                  d="M336 264.13V436c0 24.3-19.05 44-42.95 44H107c-23.95 0-43-19.7-43-44V172a44.26 44.26 0 0144-44h94.12a24.55 24.55 0 0117.49 7.36l109.15 111a25.4 25.4 0 017.24 17.77z"
                  fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="32"/>
              <path d="M200 128v108a28.34 28.34 0 0028 28h108" fill="none" stroke="currentColor" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="32"/>
              <path
                  d="M176 128V76a44.26 44.26 0 0144-44h94a24.83 24.83 0 0117.61 7.36l109.15 111A25.09 25.09 0 01448 168v172c0 24.3-19.05 44-42.95 44H344"
                  fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="32"/>
              <path d="M312 32v108a28.34 28.34 0 0028 28h108" fill="none" stroke="currentColor" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="32"/>
            </svg>
            <span>Bản nhạc</span>
          </a>
        </li>
        <li :class="{'active': isActive('DocumentList')}">
          <a :to="{name: 'DocumentList'}" v-on:click="goToRoute('DocumentList')">
            <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Document Text</title>
              <path
                  d="M416 221.25V416a48 48 0 01-48 48H144a48 48 0 01-48-48V96a48 48 0 0148-48h98.75a32 32 0 0122.62 9.37l141.26 141.26a32 32 0 019.37 22.62z"
                  fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="32"/>
              <path d="M256 56v120a32 32 0 0032 32h120M176 288h160M176 368h160" fill="none" stroke="currentColor"
                    stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/>
            </svg>
            <span>Tư liệu</span>
          </a>
        </li>
        <li :class="{'active': isActive('PlaylistList')}">
          <a v-on:click="goToRoute('PlaylistList')">
            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 17.98 17.17">
              <g data-name="Layer 2">
                <g id="playlist_icon" data-name="playlist icon" class="cls-1">
                  <g id="playlist_icon-2" data-name="playlist icon">
                    <path class="cls-2"
                          d="M.42.93A.44.44,0,0,0,0,1.38a.44.44,0,0,0,.42.45.44.44,0,0,0,.42-.45A.44.44,0,0,0,.42.93Zm0,5.41a.45.45,0,0,0,0,.91.45.45,0,0,0,0-.91Zm1.31.91H9.39a.45.45,0,0,0,.45-.45h0a.45.45,0,0,0-.45-.45H1.73a.45.45,0,0,0-.45.45h0A.45.45,0,0,0,1.73,7.24Zm0-5.41H9.39a.45.45,0,0,0,.45-.45h0A.45.45,0,0,0,9.39.93H1.73a.45.45,0,0,0-.45.45h0A.45.45,0,0,0,1.73,1.84ZM18,8.13h0Zm-.9-3.54,0,0h0A8.74,8.74,0,0,0,12.25.1.42.42,0,0,0,12.09,0L12,0V0h0a.43.43,0,0,0-.43.43v10.7A3.35,3.35,0,0,0,9,9.93a3.53,3.53,0,0,0-3.44,3.61A3.53,3.53,0,0,0,9,17.15a3.48,3.48,0,0,0,3.37-2.93.42.42,0,0,0,.06-.21V1.16A7.89,7.89,0,0,1,16.31,5h0a.44.44,0,0,0,0,.08.42.42,0,0,0,.59.16A.46.46,0,0,0,17.09,4.6ZM9,16.24a2.64,2.64,0,0,1-2.56-2.7A2.64,2.64,0,0,1,9,10.84a2.64,2.64,0,0,1,2.56,2.7A2.64,2.64,0,0,1,9,16.24Zm-4.73-4.5H1.73a.45.45,0,0,0-.45.45h0a.45.45,0,0,0,.45.45H4.27a.45.45,0,0,0,.45-.45h0A.45.45,0,0,0,4.27,11.74ZM12,17.17l.06,0H12ZM.42,11.74a.45.45,0,0,0,0,.91.45.45,0,0,0,0-.91Z"></path>
                  </g>
                </g>
              </g>
            </svg>
            <span>Playlist</span>
          </a>
        </li>
        <li>
          <a v-on:click="goToRoute('PageDetail', {slug: 'ung-ho'}, null)">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" fill="currentColor">
              <g><g><path fill="#000000" d="M150.3,157c1.2,0.8,2.5,1.2,3.8,1.2c1.3,0,2.7-0.4,3.8-1.2c2.3-1.6,57.4-38.6,77.7-75.7c5-7.8,7.7-16.8,7.7-26c0-26.5-21.5-48-48-48c-19.5,0-33.9,16.2-41.1,24.6c-7.3-8.5-21.7-24.6-41.1-24.6c-26.5,0-48,21.5-48,48c0,9.2,2.7,18.2,7.7,26C92.8,118.4,147.9,155.4,150.3,157z M112.9,21c13.7,0,25.2,13.4,31.4,20.5c3.8,4.5,5.9,6.9,9.8,6.9s6-2.5,9.8-6.9c6.2-7.2,17.6-20.5,31.4-20.5c18.9,0,34.3,15.4,34.3,34.3c0,6.7-1.9,13.1-5.6,18.7c-0.1,0.1-0.2,0.3-0.3,0.5c-15.7,29-56.8,59.5-69.6,68.5c-12.8-9-53.9-39.5-69.6-68.5c-0.1-0.2-0.2-0.3-0.3-0.5c-3.6-5.6-5.6-12.1-5.6-18.7C78.6,36.3,94,21,112.9,21L112.9,21z"/><path fill="#000000" d="M236.6,155.3c-12.5-7.3-23.1,3.5-27,7.6c-8.4,8.6-18.4,17.2-23.5,21.4c-2.2-1-4.6-1.5-7.1-1.6c-15.5-0.8-40-3.6-46.1-7.3c-6.6-3.9-11.9-7.7-16.5-11.1c-13.4-9.7-23.9-17.3-50.1-17.3c-7.6,0-14.3,2.1-20.7,4.2c-6.6,2.1-12.9,4.1-20.4,4.1c-8.3,0-15.1,6.8-15.1,15.1V209c0,8.3,6.8,15.1,15.1,15.1c14.3,0,36.8,0.7,41.7,2.4c8.9,3,29.8,15.4,37.1,19.9c0.1,0,0.2,0.1,0.3,0.2c2.6,1.5,5.6,2.2,8.7,2.2h69.8c7.4,0,15.6-6.8,18.4-11.6c9.1-15.2,41.9-56.5,42.3-56.9c0.3-0.4,0.5-0.8,0.8-1.2C248.4,170.6,245.1,160.4,236.6,155.3L236.6,155.3z M182.7,235h-69.8c-0.7,0-1.3-0.1-1.9-0.5c-2.6-1.6-28-17.2-39.8-21.1c-8.8-3-39.9-3.1-46.1-3.1c-0.8,0-1.4-0.6-1.4-1.4v-38.4c0-0.8,0.6-1.4,1.4-1.4c9.7,0,17.6-2.5,24.6-4.7c6-1.9,11.1-3.5,16.6-3.5c21.8,0,29.4,5.5,42.1,14.7c4.9,3.5,10.4,7.5,17.5,11.8c3.6,2.1,9.5,3.8,16.1,5.1c14.2,2.8,31.8,3.9,36.4,4.1c0.1,0,0.2,0,0.4,0c2.3,0,4.1,1.8,4.1,4.1c0,2.3-1.8,4.1-4.1,4.1c-0.1,0-0.2,0-0.4,0l-51.9,2.7c-4.1-0.1-13.9-7-20.4-11.6c-1.7-1.2-3.3-2.3-4.9-3.5c-3.5-2.4-6.8-4.6-9.9-6.1c-3.4-1.7-7.5-0.3-9.2,3.1c-1.7,3.4-0.3,7.5,3.1,9.2c3.5,1.8,8.2,5,13.1,8.5c10.3,7.3,20,14.2,28.4,14.2c0.1,0,0.2,0,0.4,0l52-2.7c9.7-0.1,17.6-8.1,17.6-17.8c0-2.2-0.4-4.3-1.1-6.3c5.5-4.6,15.4-13.1,24-21.9c5.8-6.1,8.2-6.6,10.2-5.3c1.8,1.1,3.2,3.2,2.4,5.2c-4.6,5.8-33.7,42.7-42.7,57.7C188.1,232.1,184.1,234.8,182.7,235L182.7,235z"/></g></g>
            </svg>
            <span>Ủng hộ</span>
          </a>
        </li>
        <li>
          <a href="javascript:void(0)" v-on:click="toogleNightMode" id="night-mode" class="btn-night-mode flex items-center">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z"></path>
                </svg>
                Chế độ tối
              <span class="btn-night-mode-switch switch flex items-center">
                  <span class="uk-switch-button"></span>
              </span>
          </a>
        </li>
        <li v-if="user">
          <a v-on:click="goToRoute('Logout')">
            <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Exit</title>
              <path
                  d="M320 176v-40a40 40 0 00-40-40H88a40 40 0 00-40 40v240a40 40 0 0040 40h192a40 40 0 0040-40v-40M384 176l80 80-80 80M191 256h273"
                  fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/>
            </svg>
            <span>Đăng xuất</span>
          </a>
        </li>
      </ul>

      <hr>

      <div class="footer-links recent-listen"
           v-if="user && user.recentListens && user.recentListens.data && user.recentListens.data.length">
        <div v-for="(item,index) in user.recentListens.data" :key="'recentListens' + index">
          <a href="javascript:void(0)"
              v-on:click="goToRoute('SongDetail', {id: item.object.id, slug: item.object.slug, prefix: getSongPrefix(item.object['__typename'])})"
              v-if="item.object">
            {{ item.object.title }}
          </a>
        </div>
      </div>
      
      <hr>

      <div class="footer-links">
        <router-link :to="{name: 'PageDetail', params: {slug: 'gioi-thieu'}}">
          Giới thiệu
        </router-link>
        <router-link :to="{name: 'PageDetail', params: {slug: 'gop-y'}}">
          Góp ý
        </router-link>
        <router-link :to="{name: 'PageDetail', params: {slug: 'yeu-cau'}}">
          Yêu cầu
        </router-link>
        <router-link :to="{name: 'PageDetail', params: {slug: 'quy-dinh'}}">
          Quy định
        </router-link>
        <router-link :to="{name: 'PageDetail', params: {slug: 'tieu-chi'}}">
          Tiêu chí
        </router-link>
        <a href="mailto:contact@bcdcnt.net">contact@bcdcnt.net</a>
      </div>
    </div>

    <!-- sidebar overly for mobile -->
    <div class="side_overly" uk-toggle="target: #wrapper ; cls: is-collapse is-active"></div>

  </div>
</template>

<script>

import {
  canManageSheet,
  isAdmin,
  canManageComment,
  canManageSong,
  canManageTag,
  canManagePeople, canManageDocument, canManageTicket, canManageUser, canManageUpload, getPrefixFromSongType
} from "../core/services/utils.service";

export default {
  name: 'Sidebar',
  props: {
    user: Object
  },
  data() {
    return {
      currentRoute: '',
      currentParams: {}
    }
  },
  methods: {
    goToRoute(route, params = null, query = null) {
      window.$("#wrapper").removeClass("is-active");
      this.$router.push({name: route, params: params, query: query});
      this.currentRoute = route;
      this.currentParams = params;
    },
    isActive(route, params) {
      if (!params) {
        return this.currentRoute === route;
      }

      return this.currentRoute === route && this.currentParams.prefix && params.prefix && this.currentParams.prefix === params.prefix;
    },
    handleSidebar() {
      window.$('.sidebar_inner ul li a').on('click', function (e) {
        if (window.$(this).closest("li").children("ul").length) {
          if (window.$(this).closest("li").is(".active-submenu")) {
            window.$('.sidebar_inner ul li').removeClass('active-submenu');
          } else {
            window.$('.sidebar_inner ul li').removeClass('active-submenu');
            window.$(this).parent('li').addClass('active-submenu');
          }
          e.preventDefault();
        }
      });
    },
    getSongPrefix(type) {
      return getPrefixFromSongType(type);
    },
    toogleNightMode() {
      window.postMessage({action: "nightMode"}, window.location.origin);
    }
  },
  mounted() {
    this.handleSidebar();
  },
  computed: {
    isAdmin() {
      return isAdmin();
    },
    canManageSheet() {
      return canManageSheet();
    },
    canManageComment() {
      return canManageComment();
    },
    canManageSong() {
      return canManageSong();
    },
    canManageTag() {
      return canManageTag();
    },
    canManagePeople() {
      return canManagePeople();
    },
    canManageDocument() {
      return canManageDocument();
    },
    canManageTicket() {
      return canManageTicket();
    },
    canManageUser() {
      return canManageUser();
    },
    canManageUpload() {
      return canManageUpload();
    }
  }
}
</script>
