<template>
  <router-link :title="people.title" :to="{name: 'PeopleDetail', params: {prefix: getRoutePrefixPeople(people._search_type), slug: people.slug}}">
    <div :class="'flex items-center space-x-4 py-1 hover:bg-gray-100 rounded-md -mx-1 px-1' + bgClass">
      <div class="w-14 h-14 flex-shrink-0 rounded-md relative">
        <img :alt="people.title" :src="people.image ? people.image.includes('https') ? people.image : 'https://img.bcdcnt.net/files/' + people.image : 'https://static.bcdcnt.net/assets/images/group/group-3.jpg'" class="h-full rounded-full w-full object-cover">
      </div>
      <div class="flex-1">
        <div class="text-lg font-semibold">
          {{ people.title }}
        </div>
        <div class="text-sm text-gray-500 mt-0.5 people-in-people" v-if="people.people_type">
          {{ people.people_type }}
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>

import {getRoutePrefixPeople} from "../../core/services/utils.service";

export default {
  name: "SearchItemPeople",
  props: {
    people: {
      type: Object,
      default() {
        return {
          title: ""
        };
      }
    },
    active: Boolean
  },
  methods: {
    getRoutePrefixPeople(type) {
      return getRoutePrefixPeople(type);
    }
  },
  computed: {
    bgClass() {
      return this.active ? ' bg-gray-100' : '';
    }
  }
}
</script>
