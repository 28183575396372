<template>
  <router-link :title="tag.title" :to="{name: 'TagDetail', params: {name: tag.slug}}">
    <div :class="'flex items-center space-x-4 py-1 hover:bg-gray-100 rounded-md -mx-1 px-1' + bgClass">
      <div class="flex-1">
        <div class="text-lg font-semibold">
          {{ tag.title }}
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>

export default {
  name: "SearchItemTag",
  props: {
    tag: {
      type: Object,
      default() {
        return {
          name: "",
          slug: ""
        };
      }
    },
    active: Boolean,
  },
  computed: {
    bgClass() {
      return this.active ? ' bg-gray-100' : '';
    }
  }
}
</script>
