<template>
  <router-link :title="user.username" :to="{name: 'UserDetail', params: {id: user.id}}">
    <div :class="'flex items-center space-x-4 py-1 hover:bg-gray-100 rounded-md -mx-1 px-1' + bgClass">
      <div class="w-14 h-14 flex-shrink-0 rounded-md relative">
        <img :alt="user.username" :src="user.image ? user.image.includes('https') ? user.image : 'https://img.bcdcnt.net/files/' + user.image : 'https://static.bcdcnt.net/assets/images/group/group-3.jpg'" class="h-full rounded-full w-full object-cover">
      </div>
      <div class="flex-1">
        <div class="text-lg font-semibold">
          {{ user.username }}
        </div>
        <div class="text-sm text-gray-500 mt-0.5 user-in-user">
          Thành viên
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>

export default {
  name: "SearchItemUser",
  props: {
    user: {
      type: Object,
      default() {
        return {
          title: ""
        };
      }
    },
    active: Boolean,
  },
  computed: {
    bgClass() {
      return this.active ? ' bg-gray-100' : '';
    }
  }
}
</script>
