<template>
  <router-link :title="song.title" :to="{name: 'SongDetail', params: {id: song.id, slug: song.slug, prefix: getPrefixFromSongType(song._search_type)}}">
    <div :class="'flex items-center space-x-4 py-1 hover:bg-gray-100 rounded-md -mx-1 px-1' + bgClass">
      <div class="w-14 h-14 flex-shrink-0 rounded-md relative">
        <img :alt="song.title" :src="song.image ? song.image.includes('https') ? song.image : 'https://img.bcdcnt.net/files/' + song.image : 'https://static.bcdcnt.net/assets/images/group/group-3.jpg'" class="h-full rounded-full w-full object-cover">
      </div>
      <div class="flex-1">
        <div class="text-lg font-semibold">
          {{ song.title }} <span uk-tooltip="title: Click để lọc thêm theo nhạc sĩ" v-if="song.composer" v-on:click.prevent.stop="searchByComposer(song.composer)" class="px-2 py-1 text-xs rounded-full bg-gray-500 text-white whitespace-nowrap">{{ song.composer }} <span class="fe icon-feather-search"></span></span>
        </div>
        <div class="text-sm text-gray-500 mt-0.5 song-in-song" v-if="song.artist">
          <span v-if="song.artist">{{ song.artist }}</span>
        </div>
        <div class="text-sm text-gray-500 mt-1" :title="thousandFormat(song.views) + ' lượt nghe'">
          <span class="uil uil-headphones"></span> {{ numberFormat(song.views) }}
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>

import {getPrefixFromSongType} from "../../core/services/utils.service";
import { numberFormat, thousandFormat } from "../../core/services/utils.service";

export default {
  name: "SearchItemSong",
  props: {
    song: {
      type: Object,
      default() {
        return {
          title: ""
        };
      }
    },
    active: Boolean
  },
  methods: {
    getPrefixFromSongType(x) {
      return getPrefixFromSongType(x);
    },
    searchByComposer(composer) {
      this.$emit("searchByComposer", composer);
    },
    numberFormat(x) {
      return numberFormat(x);
    },
    thousandFormat(x) {
      return thousandFormat(x);
    }
  },
  computed: {
    bgClass() {
      return this.active ? ' bg-gray-100' : '';
    }
  }
}
</script>
