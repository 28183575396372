<template>
  <div class="py-3 uk-link-reset">
    <div class="flex flex-col items-center justify-between lg:flex-row max-w-6xl mx-auto lg:space-y-0 space-y-3">
      <div class="space-x-2 text-gray-700 text-center">
        <router-link :to="{name: 'PageDetail', params: {slug: 'gioi-thieu'}}">
          Giới thiệu
        </router-link>
        <router-link :to="{name: 'PageDetail', params: {slug: 'gop-y'}}" class="text-dot">
          Góp ý
        </router-link>
        <router-link :to="{name: 'PageDetail', params: {slug: 'yeu-cau'}}" class="text-dot">
          Yêu cầu
        </router-link>
        <router-link :to="{name: 'PageDetail', params: {slug: 'quy-dinh'}}" class="text-dot">
          Quy định
        </router-link>
        <router-link :to="{name: 'PageDetail', params: {slug: 'tieu-chi'}}" class="text-dot">
          Tiêu chí
        </router-link>
        <router-link :to="{name: 'RoleIndex'}" class="text-dot">
          Đội ngũ
        </router-link>
        <router-link :to="{name: 'PageDetail', params: {slug: 'ung-ho'}}" class="text-dot">
          Ủng hộ
        </router-link>
        <a href="https://www.facebook.com/bcdcnt" target="_blank" class="text-dot">
          Facebook
        </a>
      </div>
      <!-- <p class="text-center"><a class="font-semibold" href="mailto:contact@bcdcnt.net">BCĐCNT</a> - Âm nhạc trong sự phát triển liền mạch của dân tộc</p> -->
      <p class="text-center"><a class="font-semibold" href="mailto:contact@bcdcnt.net">BCĐCNT</a> - Dự án âm nhạc vì mục đích cộng đồng</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer'
}
</script>
